import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Container,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import NavbarSignin from "../Navbar/NavbarSignin";
import MapView from "../MapView";

const Address = ({ onSubmit }) => {
  const validate = (values) => {
    const errors = {};

    if (!values.streetType) {
      errors.streetType = "El tipo de calle es requerido";
    }
    if (!values.streetNumber) {
      errors.streetNumber = "El número de calle es requerido";
    }
    if (!values.orientation) {
      errors.orientation = "La orientación es requerida";
    }
    if (!values.addressNumber) {
      errors.addressNumber = "El número de dirección es requerido";
    }
    if (!values.details) {
      errors.details = "Los detalles adicionales son requeridos";
    }
    if (mapCoordinates[0] === 0 && mapCoordinates[1] === 0) {
      errors.coordinates = "Las coordenadas no son válidas";
    }

    return errors;
  };

  const [mapCoordinates, setMapCoordinates] = useState([0, 0]);

  const handleSaveCoordinates = (coordinates) => {
    setMapCoordinates(coordinates);
    console.log("Coordenadas guardadas:", coordinates);
  };

  return (
    <div>
      <NavbarSignin />
      <Container maxWidth="md">
        <Typography variant="h5" gutterBottom>
          Ingresa los detalles de tu dirección:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/* Renderizar el mapa */}
            <MapView
              onCoordinatesChange={setMapCoordinates}
              onSave={handleSaveCoordinates}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Formik
              initialValues={{
                streetType: "",
                streetNumber: "",
                orientation: "",
                addressNumber: "",
                details: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                const address = {
                  direccion: `${values.streetType} ${values.streetNumber} ${values.orientation} # ${values.addressNumber} # ${values.details}`,
                  latitude: mapCoordinates[0],
                  longitude: mapCoordinates[1],
                };

                onSubmit(address)
                  .then(() => {
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setSubmitting(false);
                  });
              }}
              validate={validate}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                errors,
                touched,
                values,
              }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={touched.streetType && !!errors.streetType}
                      >
                        <InputLabel htmlFor="streetType">
                          Tipo de calle
                        </InputLabel>
                        <Field
                          as={Select}
                          id="streetType"
                          name="streetType"
                          value={values.streetType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="">
                            <em>Selecciona un tipo de calle</em>
                          </MenuItem>
                          <MenuItem value="Calle">Calle</MenuItem>
                          <MenuItem value="Avenida">Avenida</MenuItem>
                          <MenuItem value="Carrera">Carrera</MenuItem>
                          <MenuItem value="Callejón">Callejón</MenuItem>
                          <MenuItem value="Vereda">Vereda</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="streetType"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="streetNumber"
                        name="streetNumber"
                        label="Nombre o número de calle"
                        value={values.streetNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.streetNumber && !!errors.streetNumber}
                        helperText={touched.streetNumber && errors.streetNumber}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="orientation"
                        name="orientation"
                        label="Orientación"
                        value={values.orientation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.orientation && !!errors.orientation}
                        helperText={touched.orientation && errors.orientation}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="addressNumber"
                        name="addressNumber"
                        label="Número de dirección"
                        value={values.addressNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.addressNumber && !!errors.addressNumber}
                        helperText={
                          touched.addressNumber && errors.addressNumber
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="details"
                        name="details"
                        label="Detalles adicionales"
                        value={values.details}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.details && !!errors.details}
                        helperText={touched.details && errors.details}
                      />
                    </Grid>

                    <Grid item xs={12}>
                    <Button
                        type="submit"
                        disabled={isSubmitting || Object.keys(errors).length !== 0}
                        variant="contained"
                        color="primary"
                        startIcon={isSubmitting && <CircularProgress size={20} />}
                      >
                        Confirmar dirección
                      </Button>
                      {errors.coordinates && <Typography color="error">{errors.coordinates}</Typography>}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Address;
