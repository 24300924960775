import React from 'react'
import amable from "../images/about.png"
import { makeStyles } from '@mui/styles';
import { Grid, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  aboutSectionContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10%',
    flexWrap: 'wrap',
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '0 5%',
    },
  },
  aboutSectionImageContainer: {
    width: '100%',
    flex: 1,
    position: 'relative',
    marginLeft: '10%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: '4rem',
      marginLeft: '0',
      marginRight: '-20px',
      padding: '0',
      textAlign: 'center', 
     
    },
  },
  aboutSectionTextContainer: {
    maxWidth: '600px',
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      marginRight: '0',
      textAlign: 'center',
      padding: '0 5%',
      marginTop:'20%',
    },
  },
  primaryHeading: {
    fontSize: 'clamp(2rem, 5vw, 4rem)',
    color: '#dce7ea',
    zIndex: 0,
    background: 'transparent',
  },
  primaryText: {
    fontSize: 'clamp(1rem, 3vw, 1.5rem)',
    maxWidth: '500px',
    color: '#6a6a6a',
    margin: '1.5rem 0rem',
    zIndex: 0,
    background: 'transparent',
  },
}));


export default function () {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Grid container spacing={2} className={classes.aboutSectionContainer} direction={isSmallScreen ? 'column-reverse' : 'row'}>
      <Grid item xs={12} sm={8} className={classes.aboutSectionTextContainer}>
        <Box style={{ marginBottom: "50px" }}>
          <h1 className={classes.primaryHeading}>
            Fhanred donde y cuando quieras
          </h1>
          <p className={classes.primaryText}>
            Disfruta de una experiencia en línea sin interrupciones gracias a nuestra red avanzada y soporte técnico de primera clase.
          </p>
          <p className={classes.primaryText}>
            Somos una empresa dedicada a nuestros clientes.
          </p>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.aboutSectionImageContainer}>
        <img src={amable} alt="" style={{ width: '100%', height: 'auto',boxShadow: '0 0 10px rgba(0, 0, 0, 0.6)' }} />
      </Grid>
    </Grid>
  )
}
