import React from 'react';
import { makeStyles } from '@mui/styles';

import { useHistory, Link } from 'react-router-dom';
import './Home.css';
import { Card, CardContent, Typography} from '@mui/material';
import NavbarSignin from '../../../components/Navbar/NavbarSignin';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: '100px',
    margin: '20px',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
  
    
  },
 
});
const Home = () => {
  const history = useHistory();
  const classes = useStyles();



  // Si el usuario está autenticado, renderizar el contenido de Home
  return (
    <div >
      <NavbarSignin/>
      <div className="containerHome">
      <Link to="/ingresar" className="link">
        <Card className={classes.root}>
        
          <CardContent>
            <Typography variant="h5" component="div" sx={{ fontFamily: 'Arial, sans-serif', background: 'linear-gradient(to right, #ff8a00, #e52e71)', WebkitBackgroundClip: 'text', backgroundClip: 'text', WebkitTextFillColor: 'transparent', textFillColor: 'transparent', fontWeight: 'bold' }}>
              INICIA SESIÓN
            </Typography>
         
          </CardContent>
        </Card>
       </Link>
       <></>
        <Link to="/signup" className="link">
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ fontFamily: 'Arial, sans-serif', background: 'linear-gradient(to right, #ff8a00, #e52e71)', WebkitBackgroundClip: 'text', backgroundClip: 'text', WebkitTextFillColor: 'transparent', textFillColor: 'transparent', fontWeight: 'bold' }}>
              CONTRATA FHANRED
            </Typography>
           
          </CardContent>
        </Card>
        </Link>
       

      </div>
    </div>
  );
};

export default Home;