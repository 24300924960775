import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import {
  Button,
  Container,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";

function PersonalInfoStep({ onSubmit, nextStep }) {
  const [currentDate, setCurrentDate] = useState("");
  const [tel1, setTel1] = useState(null);
  const [tel2, setTel2] = useState(null);
  const [tel3, setTel3] = useState(null);
  const [razonSocial, setRazonSocial] = useState("");
  const [nDocumento, setNDocumento] = useState("");
  const [email, setEmail] = useState("");
  const [fechaCumple, setFechaCumple] = useState("");
  const [sexo, setSexo] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoPersona, setTipoPersona] = useState("");

  const userInfo = useSelector((state) => state.authentication.user);

  const isValidPhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };

  useEffect(() => {
    const formattedDate = new Date().toISOString().substr(0, 10);
    setCurrentDate(formattedDate);

    if (userInfo) {
      setRazonSocial(userInfo.razon_social || "");
      setNDocumento(userInfo.n_documento || "");
      setEmail(userInfo.email || "");
      setFechaCumple(userInfo.fecha_cumple || "");
      setSexo(userInfo.sexo || "");
      setTipoDocumento(userInfo.tipo_documento || "");
      setTipoPersona(userInfo.tipo_persona || "");
      setTel1(userInfo.tel1 || "");
      setTel2(userInfo.tel2 || "");
      setTel3(userInfo.tel3 || "");
    }
  }, [userInfo]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Completar datos personales
      </Typography>
      <Formik
        initialValues={{
          fecha_contrato: currentDate,
          tipo_persona: tipoPersona,
          razonSocial: razonSocial,
          tipo_documento: tipoDocumento,
          sexo: sexo,
          n_documento: nDocumento,
          fecha_cumple: fechaCumple,
          email: email,
          tel1: "",
          tel2: "",
          tel3: "",
          ref_tel1: "",
          ref_tel2: "",
          ref_tel3: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.razonSocial) {
            errors.razonSocial = "Campo requerido";
          }

          if (!values.n_documento) {
            errors.n_documento = "Campo requerido";
          }

          if (!values.email) {
            errors.email = "Campo requerido";
          }

          if (!values.fecha_cumple) {
            errors.fecha_cumple = "Campo requerido";
          }

          if (!values.sexo) {
            errors.sexo = "Campo requerido";
          }

          if (!values.tipo_documento) {
            errors.tipo_documento = "Campo requerido";
          }

          if (!values.tipo_persona) {
            errors.tipo_persona = "Campo requerido";
          }

          if (!isValidPhoneNumber(values.tel1)) {
            errors.tel1 = "Número de teléfono inválido";
          }

          if (!isValidPhoneNumber(values.tel2)) {
            errors.tel2 = "Número de teléfono inválido";
          }

          if (!isValidPhoneNumber(values.tel3)) {
            errors.tel3 = "Número de teléfono inválido";
          }

          

          return errors;
        }}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          console.log("Valores del formulario:", values);
          onSubmit(values);

          nextStep();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleChange, values, errors }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Fecha Contrato"
                  name="currentDate"
                  value={currentDate}
                  readOnly
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Nombre y Apellido"
                  name="razonSocial"
                  onChange={handleChange}
                  value={values.razonSocial}
                  fullWidth
                  error={!!errors.razonSocial}
                  helperText={errors.razonSocial}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Tipo de Persona"
                  name="tipo_persona"
                  onChange={handleChange}
                  value={values.tipo_persona}
                  fullWidth
                  error={!!errors.tipo_persona}
                  helperText={errors.tipo_persona}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Tipo de Documento"
                  name="tipo_documento"
                  onChange={handleChange}
                  value={values.tipo_documento}
                  fullWidth
                  error={!!errors.tipo_documento}
                  helperText={errors.tipo_documento}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Sexo"
                  name="sexo"
                  onChange={handleChange}
                  value={values.sexo}
                  fullWidth
                  error={!!errors.sexo}
                  helperText={errors.sexo}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Nº Documento"
                  name="n_documento"
                  onChange={handleChange}
                  value={values.n_documento}
                  fullWidth
                  error={!!errors.n_documento}
                  helperText={errors.n_documento}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Fecha de Nacimiento"
                  name="fecha_cumple"
                  onChange={handleChange}
                  value={values.fecha_cumple}
                  fullWidth
                  error={!!errors.fecha_cumple}
                  helperText={errors.fecha_cumple}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Referencia Contacto 1"
                  name="ref_tel1"
                  onChange={handleChange}
                  value={values.ref_tel1}
                  fullWidth
                  error={!!errors.ref_tel1}
                  helperText={errors.ref_tel1}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Referencia Contacto 2"
                  name="ref_tel2"
                  onChange={handleChange}
                  value={values.ref_tel2}
                  fullWidth
                  error={!!errors.ref_tel2}
                  helperText={errors.ref_tel2}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Referencia Contacto 3"
                  name="ref_tel3"
                  onChange={handleChange}
                  value={values.ref_tel3}
                  fullWidth
                  error={!!errors.ref_tel3}
                  helperText={errors.ref_tel3}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Teléfono de contacto 1"
                  name="tel1"
                  onChange={handleChange}
                  value={values.tel1}
                  fullWidth
                  error={!!errors.tel1}
                  helperText={errors.tel1}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Teléfono de contacto 2"
                  name="tel2"
                  onChange={handleChange}
                  value={values.tel2}
                  fullWidth
                  error={!!errors.tel2}
                  helperText={errors.tel2}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Teléfono de contacto 3"
                  name="tel3"
                  onChange={handleChange}
                  value={values.tel3}
                  fullWidth
                  error={!!errors.tel3}
                  helperText={errors.tel3}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Confirmar Datos
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default PersonalInfoStep;

