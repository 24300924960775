import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Button, Grid, Typography, Box, IconButton } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';
import {BASE_URL} from '../../Config';
import axios from 'axios';
import NavbarSignin from '../Navbar/NavbarSignin';
import { updateContract } from "../../Redux/Actions/actions";
import { CheckCircleOutline, Delete, Fingerprint } from '@mui/icons-material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function ImageAndSignatureCapture() {
  const { n_contrato } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentFrontImage, setDocumentFrontImage] = useState(null);
  const [documentBackImage, setDocumentBackImage] = useState(null);
  const [certificateImage, setCertificateImage] = useState(null);
  const [fingerprintImage, setFingerprintImage] = useState(null);
  const [signatureBlob, setSignatureBlob] = useState(null);
  const signatureRef = useRef(null);

  const handleSubmit = async () => {
    const signatureCanvas = signatureRef.current.getCanvas();
    signatureCanvas.toBlob(blob => {
      setSignatureBlob(blob);
    });

    const formData = new FormData();
    formData.append('documentFrontImage', documentFrontImage);
    formData.append('documentBackImage', documentBackImage);
    formData.append('certificateImage', certificateImage);
    formData.append('fingerprintImage', fingerprintImage);
    formData.append('signature', signatureBlob);

    try {
      await axios.post(`${BASE_URL}/firma/${n_contrato}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const nuevoEstadoContrato = 'PENDIENTE INSTALACION';
      const contractData = { estado_contrato: nuevoEstadoContrato };
      dispatch(updateContract(n_contrato, contractData));

      history.push('/');
    } catch (error) {
      console.error('Error al enviar los datos:', error);
    }
  };

  const confirmImage = async (image, setImage) => {
    const { value: confirm } = await MySwal.fire({
      title: '¿Estás conforme con la imagen?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      icon: 'question',
    });

    if (!confirm) {
      setImage(null);
    }
  };

  const handleDrop = (acceptedFiles, setImage) => {
    const imageUrl = URL.createObjectURL(acceptedFiles[0]);
    setImage(imageUrl);
    confirmImage(imageUrl, setImage);
  };

  const { getRootProps: getRootPropsFront, getInputProps: getInputPropsFront } = useDropzone({
    onDrop: acceptedFiles => handleDrop(acceptedFiles, setDocumentFrontImage),
    accept: 'image/*',
  });

  const { getRootProps: getRootPropsBack, getInputProps: getInputPropsBack } = useDropzone({
    onDrop: acceptedFiles => handleDrop(acceptedFiles, setDocumentBackImage),
    accept: 'image/*',
  });

  const { getRootProps: getRootPropsCertificate, getInputProps: getInputPropsCertificate } = useDropzone({
    onDrop: acceptedFiles => handleDrop(acceptedFiles, setCertificateImage),
    accept: 'image/*',
  });

  const { getRootProps: getRootPropsFingerprint, getInputProps: getInputPropsFingerprint } = useDropzone({
    onDrop: acceptedFiles => handleDrop(acceptedFiles, setFingerprintImage),
    accept: 'image/*',
  });

  return (
    <div className='container' style={{marginTop:"10%"}}>
      <NavbarSignin />
      <Box 
        sx={{
          padding: 4,
          backgroundColor: '#f0f0f0', 
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Carga de Imágenes y Firma Digital
        </Typography>
        <Grid container spacing={3}>
          {/* Imagen del Documento - Frente */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Imagen del Documento - Frente
            </Typography>
            <Box
              {...getRootPropsFront()}
              sx={{
                cursor: 'pointer',
                border: '2px solid black',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '4px',
                height: '250px', 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff',
              }}
            >
              <input {...getInputPropsFront()} style={{ display: 'none' }} />
              {documentFrontImage ? (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img src={documentFrontImage} alt="Document Front" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  <IconButton
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => setDocumentFrontImage(null)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </Box>
              ) : (
                <Typography>Arrastra y suelta la imagen aquí, o haz clic para seleccionarla</Typography>
              )}
            </Box>
          </Grid>

          {/* Imagen del Documento - Dorso */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Imagen del Documento - Dorso
            </Typography>
            <Box
              {...getRootPropsBack()}
              sx={{
                cursor: 'pointer',
                border: '2px solid black',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '4px',
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff',
              }}
            >
              <input {...getInputPropsBack()} style={{ display: 'none' }} />
              {documentBackImage ? (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img src={documentBackImage} alt="Document Back" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  <IconButton
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => setDocumentBackImage(null)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </Box>
              ) : (
                <Typography>Arrastra y suelta la imagen aquí, o haz clic para seleccionarla</Typography>
              )}
            </Box>
          </Grid>

          {/* Imagen del Certificado */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Imagen del Certificado
            </Typography>
            <Box
              {...getRootPropsCertificate()}
              sx={{
                cursor: 'pointer',
                border: '2px solid black',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '4px',
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff',
              }}
            >
              <input {...getInputPropsCertificate()} style={{ display: 'none' }} />
              {certificateImage ? (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img src={certificateImage} alt="Certificate" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  <IconButton
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => setCertificateImage(null)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </Box>
              ) : (
                <Typography>Arrastra y suelta la imagen aquí, o haz clic para seleccionarla</Typography>
              )}
            </Box>
          </Grid>

          {/* Imagen de la Huella Digital */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Imagen de la Huella Digital
            </Typography>
            <Box
              {...getRootPropsFingerprint()}
              sx={{
                cursor: 'pointer',
                border: '2px solid black',
                padding: '20px',
                textAlign: 'center',
                borderRadius: '4px',
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#e0f7fa',
              }}
            >
              <input {...getInputPropsFingerprint()} style={{ display: 'none' }} />
              {fingerprintImage ? (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img src={fingerprintImage} alt="Fingerprint" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  <IconButton
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                    onClick={() => setFingerprintImage(null)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Fingerprint sx={{ fontSize: 50, color: '#00796b' }} />
                  <Typography>
                    Necesitamos que utilices una tinta y en un papel blanco imprimas tu huella del índice derecho. Luego le saques una foto y la subas aquí.
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          {/* Firma Digital */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Firma Digital
            </Typography>
            <Box
              sx={{
                border: '2px solid black',
                padding: '10px',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#ffffff', 
              }}
            >
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: 400,
                  height: 150,
                  className: 'signature-canvas',
                }}
              />
            </Box>
          </Grid>

          {/* Botón de envío */}
          <Grid item xs={12}>
            <Button variant="contained" sx={{ marginBottom: '1rem', background: "#206482" }} onClick={handleSubmit}>
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ImageAndSignatureCapture;








