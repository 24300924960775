import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, Box, Grid, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchSummary } from "../../../Redux/Actions/actions";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    "& > *": {
      marginRight: theme.spacing(2),
      color: "white",
    },
  },
  gridContainer: {
    marginTop: "4%",
    margin: "20px auto", // Margen centralizado en todas las pantallas
    maxWidth: "1200px", // Ancho máximo del contenedor principal
    padding: theme.spacing(2), // Padding interno
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#e0e0e0",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
    width: "100%",
  },
  textFieldContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const Summary = () => {
  const classes = useStyles();
  const history = useHistory();
  const [nDocumento, setNDocumento] = useState("");
  const [rows, setRows] = useState([]);
  const [saldoTotal, setSaldoTotal] = useState(0);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const summaryData = useSelector((state) => state.summary);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const userRole = useSelector((state) => state.authentication.user.id_role);
  const userNDocumento = useSelector(
    (state) => state.authentication.user.n_documento
  );

  const handleNDocumentoChange = (event) => {
    const value = event.target.value;
    setNDocumento(value);
    if (value.trim() === "") {
      setRows([]);
      setSaldoTotal(0);
    }
  };

  const handleSubmit = () => {
    if (nDocumento.trim() !== "" && !isNaN(nDocumento.trim())) {
      setIsRequestSent(true);
      dispatch(fetchSummary(nDocumento.trim()));
    } else {
      console.error("Número de documento no válido");
    }
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      isRequestSent &&
      summaryData &&
      summaryData.formattedBills &&
      summaryData.formattedCash &&
      ((nDocumento.trim() !== "" && !isNaN(nDocumento.trim())) ||
        userRole === 1)
    ) {
      let saldo = 0;
      const newRows = [
        ...summaryData.formattedBills.map((bill, index) => {
          saldo += bill.amount;
          return {
            id: `${bill.id !== undefined ? bill.id : index}`,
            issue_date: new Date(bill.date).toLocaleString("es-ES", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
            type: getTypeName(bill.type),
            price: bill.amount,
            saldo: saldo,
            qrcode: bill.qrUrl,
          };
        }),
        ...summaryData.formattedCash.map((cashItem, index) => {
          saldo -= cashItem.amount;
          return {
            id: `R ${cashItem.id !== undefined ? cashItem.id : index}`,
            issue_date: new Date(cashItem.date).toLocaleString("es-ES", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
            type: getTypeName(cashItem.type),
            price: -cashItem.amount,
            saldo: saldo,
          };
        }),
        {
          id: "",
          issue_date: "",
          type: "Saldo Total",
          price: "",
          saldo: saldo,
        },
      ];

      newRows.sort((a, b) => new Date(a.issue_date) - new Date(b.issue_date));

      setRows(newRows);
      setSaldoTotal(saldo);
      setIsRequestSent(false);
    }
  }, [
    isAuthenticated,
    summaryData,
    nDocumento,
    isRequestSent,
    userRole,
    userNDocumento,
  ]);

  useEffect(() => {
    if (isAuthenticated && userRole === 1) {
      setNDocumento(userNDocumento);
      setIsRequestSent(true);
      dispatch(fetchSummary(userNDocumento));
    }
  }, [isAuthenticated, userRole, userNDocumento, dispatch]);

  const getTypeName = (type) => {
    const typeMap = {
      Bill: "Factura",
      DebitNote: "Factura",
      CreditNote: "Factura",
      Cash: "Recibo",
    };
    return typeMap[type] || type;
  };

  const columns = [
    { field: "issue_date", headerName: "Fecha Emisión", width: 130 },
    { field: "id", headerName: "N° Comprobante", width: 150 },
    { field: "type", headerName: "Tipo Comprobante", width: 160 },
    { field: "price", headerName: "Importe", width: 100 },
    { field: "saldo", headerName: "Saldo", width: 100 },
    {
      field: "qrcode",
      headerName: "Ver Comprobante",
      width: 150,
      renderCell: (params) => {
        if (params.row.type === "Factura") {
          return (
            <a href={params.value} target="_blank" rel="noopener noreferrer">
              Ver Detalle
            </a>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (

    <Grid
      container
      className={classes.gridContainer}
      justifyContent="center"
      alignItems="center"
    >
      {userRole !== 1 && (
        <>
          <h2 className={classes.title}>Ingrese N° de documento</h2>
          <Grid item className={classes.textFieldContainer}>
            <TextField
              label="Número de documento"
              value={nDocumento}
              onChange={handleNDocumentoChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSubmit} edge="end">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </>
      )}

      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          style={{ marginTop: 10 }}
        />
        <Grid item style={{ marginTop: 10 }}>
          Saldo Total: {saldoTotal}
        </Grid>
        <Button
          variant="contained"
          style={{ background: "#206482", padding: "10px", color: "white" }}
          type="button"
          onClick={() => history.goBack()}
        >
          Volver
        </Button>
      </Box>
    </Grid>
  );
};

export default Summary;
