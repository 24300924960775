import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Stepper, Step, StepLabel, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonalInfoStep from './PersonalInfoStep';
import SelectPlan from './SelectPlan';
import Address from './Address';
import { useDispatch } from 'react-redux';
import { createContract } from '../../Redux/Actions/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
    stepper: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(6), 
        backgroundColor: 'transparent',
    },
    stepLabel: {
        fontSize: '0.9rem',
    },
    linearProgress: {
        height: '12px',
        marginBottom: theme.spacing(2), 
    },
}));

function ContractForm() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [personalInfo, setPersonalInfo] = useState({});
    const [planInfo, setPlanInfo] = useState({});
    const [addressInfo, setAddressInfo] = useState({});

    const handlePersonalInfoSubmit = (data) => {
        setPersonalInfo(data);
        nextStep();
    };

    const handlePlanSubmit = (data) => {
        setPlanInfo(data);
        nextStep();
    };

    const handleAddressSubmit = (data) => {
        setAddressInfo(data);
        createContractBackend({ ...personalInfo, ...planInfo, ...data });
    };

    const createContractBackend = async (contractData) => {
        try {
            await dispatch(createContract(contractData));
            MySwal.fire({
                title: 'Solicitud Exitosa',
                text: 'Revisa tu correo',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            history.push('/perfil');
        } catch (error) {
            console.error('Error al crear contrato:', error);
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const steps = ['Información Personal', 'Selección de Plan', 'Dirección'];

    return (
        <Container style={{marginTop:"7%"}}>
           
            <Stepper activeStep={step - 1} className={classes.stepper}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel className={classes.stepLabel}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
          
            {step === 1 && <PersonalInfoStep onSubmit={handlePersonalInfoSubmit} nextStep={nextStep} />}
            {step === 2 && <SelectPlan onSubmit={handlePlanSubmit} nextStep={nextStep} />}
            {step === 3 && <Address onSubmit={handleAddressSubmit} />}
        </Container>
    );
}

export default ContractForm;

