import React, { useState } from 'react';
import { Box, Drawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import { HiOutlineBars3 } from "react-icons/hi2";
import Logo from "../images/LOGO2.png";
import trabajador from "../images/banner3.jpeg";
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import BASE_FRONT from '../../../../Config';
import Dividers from '../animacion';

const useStyles = makeStyles((theme) => ({
    navbar: {
        // backgroundColor: "#1b2930",
        position: 'relative',
        overflow: 'hidden',
    },
    navbarLinksContainer: {
        position: 'relative',
    },
    bannerImgContainer: {
        position: 'relative',
        overflow: 'hidden',
    },
    bannerImg: {
        width: '100%',
        zIndex: 0,
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        opacity: 0.7,
    },
    logoOverlay: {
        position: 'absolute',
        top: '10%',
        right: '5%',
        transform: 'translateY(-50%)',
        zIndex: 1,
    },
    logoNav: {
        width: '200px', 
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100px', 
            height: 'auto',
            top:'5%',
        },
    },
    navbarLink: {
        top: '10px', 
        left: '80px', 
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        textDecoration: 'none',
        position: 'absolute',
        zIndex: 1,
        color: '#dce7ea',
        fontSize: '1.2rem',
        fontWeight: 600,
        background: 'transparent',
    },
    menuButton: {
        position: 'fixed',
        top: '5px',
        left: 0,
        color: 'white',
        fontSize: '2rem',
        padding: '10px',
        zIndex: 1000,
        marginRight: '2.5rem',
    },
    drawer: {
        width: 250,
    },
    listItemIcon: {
        color: 'white',
    },
    listItemText: {
        color: '#205677',
    },
    primaryButton1: {
        padding: '0.9rem 1.75rem',
        backgroundColor: '#1d4658',
        outline: 'none',
        border: 'none',
        borderRadius: '5rem',
        fontSize: '1.1rem',
        cursor: 'pointer',
        fontWeight: 600,
        transition: '0.2s',
        color: '#dce7ea',
        bottom: '-200px',
        right: '20px',
        zIndex: 1000,
        position: 'absolute',
        textDecoration:'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function Navbar() {
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(false);
    const [isHomeVisible, setIsHomeVisible] = useState(false);

    // Simulamos una transición de carga
    React.useEffect(() => {
      setTimeout(() => {
        setIsHomeVisible(true);
      }, 500);
    }, []);
    const menuOptions = [
        {
            text: "INGRESA",
            icon: <HomeIcon />,
            path: `${BASE_FRONT}/ingresar`,
        },
        {
            text: "REGISTRATE",
            icon: <InfoIcon />,
            path: `${BASE_FRONT}/signup`,
        },
    ];

    return (
        <nav className={classes.navbar}>
            <div className={classes.navbarLinksContainer}>
                <img className={classes.bannerImg} src={trabajador} alt="" />
                <Dividers isVisible={isHomeVisible} /> {/* Línea de división animada */}
                <div className={classes.logoOverlay}>
                    <img className={classes.logoNav} src={Logo} alt="" />
                    <Link to="/ingresar" className={classes.primaryButton1}>INGRESAR</Link>
                </div>
                <Link to="/ingresar" className={classes.navbarLink} style={{ top: '15px', left: '80px', marginRight: '10px' }}>Ingresá</Link>
                <Link to="/signup" className={classes.navbarLink} style={{ top: '15px', left: '180px', marginRight: '10px' }}>Registrate</Link>
            </div>
            <div className={classes.menuButton}>
                <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
            </div>
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
                <Box
                    className={classes.drawer}
                    role="presentation"
                    onClick={() => setOpenMenu(false)}
                    onKeyDown={() => setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton component={Link} to={item.path.replace(BASE_FRONT, '')}>
                                    <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} className={classes.listItemText} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </nav>
    );
}

export default Navbar;


