import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { clientsLinks } from '../../../data';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../../Redux/Actions/actions';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  clientsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    // backgroundColor: '#242d58',
    background: 'linear-gradient(to bottom, #242d58, #79c8ce)',
    minHeight: '100vh',
  },
  clientItem: {
    margin: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.primary,
    
    // background: 'linear-gradient(to bottom, #242d58, #79c8ce)',
    borderRadius: '10px',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  name: {
    fontSize: '1.4rem',
    color: '#242d58',
  },
}));

function Customers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <div className={classes.clientsContainer}>
      <Grid container spacing={2} justifyContent="center">
        {clientsLinks.map(({ name, icon, path }, index) => (
          <Grid item key={index} className={classes.clientItem}>
            <NavLink to={path} onClick={() => setShowMenu(!showMenu)} className={classes.link}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classes.icon}>{icon}</div>
                <Typography variant="h6" className={classes.name}>{name}</Typography>
              </Paper>
            </NavLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Customers;
