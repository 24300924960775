import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  optionsPlan,
  optionsEstrato,
  optionsZona,
  optionsVivienda,
  optionsMunicipio,
  
} from "./arrayOptions";

function SelectPlan({ onSubmit, nextStep }) {
  const [filteredOptionsPlan, setFilteredOptionsPlan] = useState(optionsPlan);


  const handleIdStratusChange = (event) => {
    const idStratusValue = event.target.value;
    // Filter options based on idStratus value
    const filteredPlans = idStratusValue <= 3
      ? optionsPlan.filter(option => option.label.includes('SIN IVA'))
      : optionsPlan.filter(option => option.label.includes('CON IVA'));
    setFilteredOptionsPlan(filteredPlans);
  };


  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Selección de Plan
      </Typography>
      <Formik
        initialValues={{
          idStratus: "",
          zona: "",
          tipoVivienda: "",
          municipio: "",
          barrio_vereda: "",
          facturaDigital: "",
          name_plan: "",
        }}
        validate={(values) => {
          const errors = {};

          const selectFields = [
            "idStratus",
            "zona",
            "tipoVivienda",
            "municipio",
            "facturaDigital",
          ];
          selectFields.forEach((field) => {
            if (!values[field]) {
              errors[field] = "Selecciona una opción";
            }
          });

          if (!values.barrio_vereda) {
            errors.barrio_vereda = "Campo requerido";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          onSubmit(values);
          nextStep();
          setSubmitting(false);
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
          

              <Grid item xs={6}>
              <FormControl fullWidth error={touched.idStratus && !!errors.idStratus}>
                  <InputLabel htmlFor="idStratus">Estrato</InputLabel>
                  <Select
                    id="idStratus"
                    name="idStratus"
                    value={values.idStratus}
                    onChange={(e) => {
                      handleChange(e);
                      handleIdStratusChange(e);
                    }}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="">
                      <em>Selecciona una opción</em>
                    </MenuItem>
                    {optionsEstrato.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.idStratus && errors.idStratus && (
                    <Typography color="error">{errors.idStratus}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth error={touched.zona && !!errors.zona}>
                  <InputLabel htmlFor="zona">Zona</InputLabel>
                  <Select
                    id="zona"
                    name="zona"
                    value={values.zona}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="">
                      <em>Selecciona una opción</em>
                    </MenuItem>
                    {optionsZona.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.zona && errors.zona && (
                    <Typography color="error">{errors.zona}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth error={touched.tipoVivienda && !!errors.tipoVivienda}>
                  <InputLabel htmlFor="tipoVivienda">Tipo de Vivienda</InputLabel>
                  <Select
                    id="tipoVivienda"
                    name="tipoVivienda"
                    value={values.tipoVivienda}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="">
                      <em>Selecciona una opción</em>
                    </MenuItem>
                    {optionsVivienda.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.tipoVivienda && errors.tipoVivienda && (
                    <Typography color="error">{errors.tipoVivienda}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth error={touched.municipio && !!errors.municipio}>
                  <InputLabel htmlFor="municipio">Municipio</InputLabel>
                  <Select
                    id="municipio"
                    name="municipio"
                    value={values.municipio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="">
                      <em>Selecciona una opción</em>
                    </MenuItem>
                    {optionsMunicipio.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.municipio && errors.municipio && (
                    <Typography color="error">{errors.municipio}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="barrio_vereda"
                  name="barrio_vereda"
                  label="Barrio"
                  value={values.barrio_vereda}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.barrio_vereda && !!errors.barrio_vereda}
                  helperText={touched.barrio_vereda && errors.barrio_vereda}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth error={touched.facturaDigital && !!errors.facturaDigital}>
                  <InputLabel htmlFor="facturaDigital">Factura Digital</InputLabel>
                  <Select
                    id="facturaDigital"
                    name="facturaDigital"
                    value={values.facturaDigital}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="">
                      <em>Selecciona una opción</em>
                    </MenuItem>
                    <MenuItem value={"true"}>Si</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                  </Select>
                  {touched.facturaDigital && errors.facturaDigital && (
                    <Typography color="error">{errors.facturaDigital}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth error={touched.name_plan && !!errors.name_plan}>
                  <InputLabel htmlFor="name_plan">Plan</InputLabel>
                  <Select
                    id="name_plan"
                    name="name_plan"
                    value={values.name_plan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="">
                      <em>Selecciona una opción</em>
                    </MenuItem>
                    {filteredOptionsPlan.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.name_plan && errors.name_plan && (
                    <Typography color="error">{errors.name_plan}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">
                  Confirmar Datos
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default SelectPlan;
