import React from 'react';
import { ThemeProvider, Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavbarLanding from './NavbarLanding';
import Home from './Home';
import Work from './Work';
import Contact from './Contact';
import LeafletMap from './LeafletMap';
import Footer from './Footer';
import About from './About';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1b2930',
    },
    secondary: {
      main: '#dce7ea',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(() => ({
  fullHeight: {
    minHeight: '100vh',
    
    
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  halfWidth: {
    width: '100%',
    height: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
}));

export default function Landing() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#143e59', minHeight: '100vh'}}>
        <NavbarLanding />
        <Container maxWidth="none" className={classes.fullHeight}>
          <Grid container spacing={2} className={classes.fullHeight}>
            <Grid item xs={12} style={{ marginTop: '60px', marginBottom: '60px' }}>
              <Home />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '40px', marginBottom: '60px' }}>
              <About />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '40px', marginBottom: '60px' }}>
              <Work />
            </Grid>
            <Grid container spacing={1} className={classes.fullHeight}>
              <Grid item xs={12} sm={6} className={classes.centeredContainer}>
                <div className={classes.halfWidth}>
                  <Contact />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.centeredContainer}>
                <div className={classes.halfWidth}>
                  <LeafletMap />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Footer />
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}
