import {
  SIGNIN_USER,
  CREATE_USER,
  GET_USERS,
  INCREMENT_NUMBER_FACT,
  CLEAN_DETAIL,
  FETCH_USER_CONTRACTS_SUCCESS,
  FETCH_CONTRACT_DETAILS_REQUEST,
  FETCH_CONTRACT_DETAILS_SUCCESS,
  FETCH_CONTRACT_DETAILS_FAILURE,
  FETCH_LAST_RECEIPT_NUMBER_SUCCESS,
  FETCH_LAST_RECEIPT_NUMBER_FAILURE,
  FETCH_SUMMARY_REQUEST,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  LOGOUT_USER,
  CLEAN_CONTRACT_DETAILS,
  SEND_PAYMENT_REQUEST,
  SEND_PAYMENT_SUCCESS,
  SEND_PAYMENT_FAILURE,
  CLEAN_USER_CONTRACTS,
  SHOW_NO_CONTRACTS_MODAL,
  CLOSE_MODAL,
  ADD_RECEIPT,
  FETCH_MOVEMENTS_BY_CASHIER_REQUEST,
  FETCH_MOVEMENTS_BY_CASHIER_SUCCESS,
  FETCH_MOVEMENTS_BY_CASHIER_FAILURE,
  FETCH_ALL_TICKETS_REQUEST,
  FETCH_ALL_TICKETS_SUCCESS,
  FETCH_ALL_TICKETS_FAILURE,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAILURE,
  CONTRACT_DETAIL_REQUEST,
  CONTRACT_DETAIL_SUCCESS,
  CONTRACT_DETAIL_FAILURE,
  FETCH_ASSIGNED_TASKS_REQUEST,
  FETCH_ASSIGNED_TASKS_SUCCESS,
  FETCH_ASSIGNED_TASKS_FAILURE,
  UPDATE_CONTRACT_REQUEST,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_FAILURE,
  UPDATE_CONTRACT_AND_IMAGES_REQUEST,
  UPDATE_CONTRACT_AND_IMAGES_SUCCESS,
  UPDATE_CONTRACT_AND_IMAGES_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_USER_INFO,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  CARGAR_ARTICULOS_REQUEST,
  CARGAR_ARTICULOS_EXITO,
  CARGAR_ARTICULOS_ERROR,
  GET_CUOTAS_REQUEST,
  GET_CUOTAS_SUCCESS,
  GET_CUOTAS_FAILURE,
  FETCH_FINANCIACIONES_REQUEST,
  FETCH_FINANCIACIONES_SUCCESS,
  FETCH_FINANCIACIONES_FAILURE,
  FETCH_BILLS_REQUEST,
  FETCH_BILLS_SUCCESS,
  FETCH_BILLS_FAILURE,
  GET_PROMOS_REQUEST,
  GET_PROMOS_SUCCESS,
  GET_PROMOS_FAILURE,
  FETCH_TICKET_BY_ID_SUCCESS,


} from "../Actions/actions-types";

const initialState = {
  authentication: {
    token: null,
    user: null,
    isAuthenticated: false,


  },
  cuotas: [],
  financiaciones: [],
  contractRoute: null,
  userInfo: {},
  usersData: null,
  assign: {},
  promos: [],
  articulos: [],
  bills30Days: [],
  tasks: [],
  numberFact: 1,
  imagesData: null,
  userContracts: [],
  contractDetails: null,
  selectedContract: null,
  planDetails: null,
  lastReceiptNumber: null,
  isNoContractsModalOpen: false,
  movements: {
    cashierName: null,
    paymentMethod: null,
    data: [],
    loading: false,
    error: null,
  },

  summary: {
    userInfo: {},
    saldo: null,
    bills: [],
    debitNotes: [],
    creditNotes: [],
    cash: [],
    formattedCash: [],
    loading: false,
    error: null,
  },
  paymentDetails: [],
  loading: false,
  error: null,
  ticketsList: [],
  currentTicketDetail: {}
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_USER:
     
      return {
        ...state,
        authentication: {
          token: action.payload.token,
          user: action.payload.user,
          isAuthenticated: true,
        },
        cashierName: action.payload.user.cashierName,
      };
    case LOGOUT_USER:
      return {
        ...state,
        authentication: {
          token: null,
          user: null,
          isAuthenticated: false,
        },
      }
    case GET_USERS:
      
      return {
        ...state,
        usersData: action.payload,

      };
    case GET_USER_INFO:
      
      return {
        ...state,
        userInfo: action.payload,
      };
    case CREATE_USER:
      return {
        ...state,
        userInfo: action.payload,
      };
    case CLEAN_DETAIL:
      return {
        ...state,
        userInfo: {},
        loginError: false,
      };
    case INCREMENT_NUMBER_FACT:
      return {
        ...state,
        numberFact: state.numberFact + 1,
      };

    case FETCH_LAST_RECEIPT_NUMBER_SUCCESS:
      return {
        ...state,
        lastReceiptNumber: action.payload,
      };
    case FETCH_LAST_RECEIPT_NUMBER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_CONTRACT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_DETAILS_SUCCESS:
      return {
        ...state,
        planDetails: action.payload,
        loading: false,
      };
    case FETCH_CONTRACT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONTRACT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CONTRACT_DETAIL_SUCCESS:
     
      return {
        ...state,
        contractDetails: action.payload,
        loading: false,
      };
    case CONTRACT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAN_CONTRACT_DETAILS:
     
      return {
        ...state,
        contractDetails: null,
      };


    case FETCH_USER_CONTRACTS_SUCCESS:
      return {
        ...state,
        userContracts: action.payload,
        loading: false,
      };


    case CLEAN_USER_CONTRACTS:
     
      return {
        ...state,
        userContracts: [],
      };
    case SHOW_NO_CONTRACTS_MODAL:
      return {
        ...state,
        isNoContractsModalOpen: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isNoContractsModalOpen: false,
      };
    case FETCH_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SUMMARY_SUCCESS:
      
      return {
        ...state,
        summary: action.payload,
      };
    case FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SEND_PAYMENT_REQUEST:
      return { ...state, loading: true, error: null, paymentDetails: null };
    case SEND_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentDetails: action.payload.newIngreso,
      };

    case SEND_PAYMENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_RECEIPT:
      return {
        ...state,
        summary: {
          ...state.summary,
          cash: [...state.summary.cash, action.payload],
        },
      };
    case FETCH_MOVEMENTS_BY_CASHIER_REQUEST:
      
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_MOVEMENTS_BY_CASHIER_SUCCESS:
     
      return {
        ...state,
        data: action.payload,
        error: null,
      };

    case FETCH_MOVEMENTS_BY_CASHIER_FAILURE:
      console.error("Error fetching movements by cashier:", action.error);
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      };
    case CREATE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contractRoute: action.payload,
        error: '',
      };
    case CREATE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        contractRoute: null,
        error: action.payload,
      };
    case FETCH_ASSIGNED_TASKS_REQUEST:
     
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ASSIGNED_TASKS_SUCCESS:
      
      return {
        ...state,
        assign: action.payload,
        loading: false,
      };
    case FETCH_ASSIGNED_TASKS_FAILURE:
     
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contractDetails: action.payload,
        error: null,
      };
    case UPDATE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_CONTRACT_AND_IMAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case UPDATE_CONTRACT_AND_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        imagesData: action.payload.imagesData, // Actualiza imagesData en el estado con los datos proporcionados
      };
    case UPDATE_CONTRACT_AND_IMAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: {
          ...state.userInfo,
          // Actualiza el estado con los datos de cambio de contraseña si es necesario
        },
        error: null,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_TICKETS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ALL_TICKETS_SUCCESS:
      
      return {
        ...state,
        ticketsList: action.payload,
      };
    case FETCH_ALL_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_TICKET_SUCCESS:
      
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CARGAR_ARTICULOS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CARGAR_ARTICULOS_EXITO:
      return {
        ...state,
        articulos: action.payload,
        loading: false,
        error: null
      };
    case CARGAR_ARTICULOS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_FINANCIACIONES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_FINANCIACIONES_SUCCESS:
      return {
        loading: false,
        financiaciones: action.payload,
        error: ''
      };
    case FETCH_FINANCIACIONES_FAILURE:
      return {
        loading: false,
        financiaciones: [],
        error: action.error
      };

    case GET_CUOTAS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CUOTAS_SUCCESS:
      return {
        ...state,
        cuotas: action.payload,
        loading: false,
        error: null,
      };
    case GET_CUOTAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_BILLS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BILLS_SUCCESS:
      return {
        loading: false,
        bills30Days: action.payload,
        error: ''
      };
    case FETCH_BILLS_FAILURE:
      return {
        loading: false,
        bills30Days: [],
        error: action.payload
      };
    case GET_PROMOS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_PROMOS_SUCCESS:
      return {
        ...state,
        loading: false,
        promos: action.payload
      };
    case GET_PROMOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_TICKET_BY_ID_SUCCESS:
      return {
        ...state,
        loading:false,
        currentTicketDetail: action.payload
      }

    default:
      return state;
  }
};

export default rootReducer;
