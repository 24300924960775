import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { BsLock } from "react-icons/bs";
import { Card, CardContent, Typography, TextField, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { changePassword } from "../../../Redux/Actions/actions";

const ChangePassword = () => {
    const { n_documento } = useParams();
    console.log("asi llega al front",n_documento)
    const dispatch = useDispatch();
    const history = useHistory();

  
    const [showPassword, setShowPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

 
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

 
    const submitHandler = async (e) => {
      e.preventDefault();
  
      if (newPassword !== confirmPassword) {
          Swal.fire({
              icon: "error",
              title: "Error",
              text: "La nueva contraseña y la confirmación no coinciden.",
          });
          return;
      }
  
      const changePasswordPayload = {
          currentPassword,
          newPassword,
      };
  
    
      console.log("Payload enviado:", changePasswordPayload);
  
     
      const result = await dispatch(changePassword(n_documento, changePasswordPayload));
  
      console.log("Resultado de changePassword:", result);
  
      if (result.success) {
          Swal.fire({
              icon: "success",
              title: "Éxito",
              text: "La contraseña fue cambiada exitosamente.",
            }).then(() => {
              
                history.push("/perfil");
            });
      } else {
          Swal.fire({
              icon: "error",
              title: "Error",
              text: result.errorMessage || "Hubo un problema al cambiar la contraseña.",
          });
      }
  };
  
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: 'rgba(32,94,125,0.10)' }}>
        <Card style={{ maxWidth: 400 }}>
            <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                    Cambiar contraseña
                </Typography>

                <form onSubmit={submitHandler}>
                    <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={currentPassword}
                        label="Contraseña actual"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            ),
                        }}
                        margin="normal"
                        variant="outlined"
                    />

                    <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        label="Nueva contraseña"
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                </IconButton>
                            ),
                        }}
                        margin="normal"
                        variant="outlined"
                    />

                    <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={confirmPassword}
                        label="Confirmar nueva contraseña"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            ),
                        }}
                        margin="normal"
                        variant="outlined"
                    />

                    <Button
                        fullWidth
                        type="submit"
                        variant="contained" 
                        style={{ background: "#206482", marginTop: "16px", color:'white', padding: "8px"  }}
                        startIcon={<BsLock />}
                        
                    >
                        Cambiar contraseña
                    </Button>
                </form>
            </CardContent>
        </Card>
    </div>
);
};

export default ChangePassword;
