import axios from 'axios'
import { ErrorMessage, Field, Form, Formik} from 'formik';
import { Grid, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './ForgotPassword.module.css';
import {BASE_URL} from '../../Config'
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    icon: {
      marginRight: theme.spacing(1), 
      verticalAlign: 'middle',
  },
  inputField: {
      marginBottom: theme.spacing(2), // Ajusta el margen inferior del campo de entrada
  },
  
}));


const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isSent, setIsSent] = useState(false);
  return (
    <Grid container justifyContent="center" style={{marginTop:"150px"}}>
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email =
              'Por favor proporcione la información necesaria para restablecer su contraseña.';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
  const response = await axios.post(`${BASE_URL}/user/forgotpassword`, values);
  
} catch (error) {
  console.error(error);
}
          
          setIsSent(true);
          setSubmitting(false);
          resetForm();
          history.push('/');
        }}
      >
        {({ isSubmitting, errors, setFieldValue  }) => (
          <Grid item xs={12} sm={8} md={6} className={classes.container}>
            
              <Form >
              <Typography variant="body1" gutterBottom>
                                <MailOutlineIcon className={classes.icon} /> Necesitamos su dirección de correo electrónico para recuperar su contraseña.
                            </Typography>
                            <Field
                                as={TextField}
                                type="text"
                                name="email"
                                placeholder="Correo electrónico"
                                variant="outlined"
                                fullWidth
                                className={classes.inputField}
                                onChange={(e) => {
                                    const upperCaseEmail = e.target.value.toUpperCase();
                                    setFieldValue('email', upperCaseEmail);
                                }}
                            />
              <ErrorMessage
                name="email"
                component={() => <div className="error-message">{errors.email}</div>}
              />

                <br />
                <Button variant="contained" style={{ background: "#206482", marginRight: "10px", marginTop: "30px" }} type="submit" disabled={isSubmitting}>
                  Enviar
                </Button>
                {isSent && <p className={style.exito}>Enviamos un mensaje a su correo </p>}
              </Form>
            
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};
export default ForgotPassword;
