import React, { useState } from 'react';
import { Button, Card, CardContent, Typography, Dialog, DialogContent, DialogActions, Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import foto from '../images/fibra.jpg';
import ImageSlider from './Slider';
import { URL_FRONT } from '../../../../Config.js';

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    padding: '0.9rem 1.75rem',
    backgroundColor: '#dce7ea',
    outline: 'none',
    border: 'none',
    borderRadius: '5rem',
    fontSize: '1.1rem',
    cursor: 'pointer',
    fontWeight: 600,
    transition: '0.2s',
    '&:hover': {
      backgroundColor: 'rgb(234, 234, 234)',
    },
  },
  primaryButton1: {
    padding: '0.9rem 1.75rem',
    backgroundColor: '#1d4658',
    outline: 'none',
    border: 'none',
    borderRadius: '5rem',
    fontSize: '1.1rem',
    cursor: 'pointer',
    fontWeight: 600,
    transition: '0.2s',
    color: '#dce7ea',
    bottom: '-380px', 
    right: '20px',  
    zIndex: 1000, 
    position: 'absolute',
  },
  customCard: {
    width: '600px',
    height: '400px',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${foto})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      opacity: 0.6, 
      zIndex: 1,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      padding: '0 5%',
      '&::before': {
        backgroundSize: 'contain',
      },
    },
  },
  customCardContent: {
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',

  },
  homeImageSection: {
    maxWidth: '600px',
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%',  
      marginBottom: '1rem',
      justifyContent: 'center',
      marginLeft: '0', 
      marginRight: '0', 
      alignItems: 'center',
      padding: '0 5%',
    },
  },
  homeTextSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '50%',
    marginTop: '10%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0', 
      marginRight: '0', 
      alignItems: 'center',
      textAlign: 'center',
      width: '100%', 
      padding: '0 5%', 
    },
  },
  primaryHeading: {
    fontSize: 'clamp(2rem, 5vw, 4rem)',
    color: '#dce7ea',
    maxWidth: '600px',
    zIndex: 0,
    background: 'transparent',
  },
  primaryText: {
    fontSize: 'clamp(1rem, 3vw, 1.5rem)',
    maxWidth: '500px',
    color: '#6a6a6a',
    margin: '1.5rem 0rem',
    zIndex: 0,
    background: 'transparent',
  },
  sliderBox: {
    marginTop: '20%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      maxWidth: '50%',  
      marginBottom: '1rem',
      objectFit: 'contain',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft:'25%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50%', 
      marginBottom: '1rem',
      objectFit: 'contain',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  dialogContent: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: '16px', 
    },
  },
  dialogCard: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      '&::before': {
        backgroundSize: 'contain',
      },
    },
  },
  dialogTypography: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem', 
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    window.open(`${URL_FRONT}/signup`, '_blank'); 
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box className={classes.sliderBox}>
            <ImageSlider />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box className={classes.homeTextSection}>
            <h1 className={classes.primaryHeading}>
              Tu conexión de internet favorita, rápida y confiable.
            </h1>
            <p className={classes.primaryText}>
              Los técnicos expertos se encargan de todo el trabajo, desde la instalación hasta la configuración, para que puedas disfrutar de una conexión rápida y estable.
            </p>
            <button className={classes.primaryButton} onClick={handleOpen}>
              Saber Más
            </button>
          </Box>
        </Grid>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={isSmallScreen}>
          <DialogContent className={classes.dialogContent}>
            <Card className={`${classes.customCard} ${classes.dialogCard}`}>
              <CardContent className={classes.customCardContent}>
                <Typography variant="h2" component="div" className={classes.dialogTypography} style={{ fontWeight: 'bold', color: '#143e59' }}>
                  Contratá nuestros servicios y accedé a muchos beneficios
                </Typography>
                <Typography variant="body2">
                  <button style={{ marginTop: "40px" }} onClick={handleButtonClick} className={classes.primaryButton}>
                    Registrate
                  </button>
                </Typography>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: 'white', background: '#6a6a6a' }}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Grid>
  );
}