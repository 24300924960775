import {BASE_URL} from "../../Config";

import axios from "axios";
import {
  SIGNIN_USER,
  LOGOUT_USER,
  CLEAN_DETAIL,
  CREATE_INVOICE,
  INCREMENT_NUMBER_FACT,
  CREATE_USER,
  GET_USERS,
  FETCH_CONTRACT_DETAILS_SUCCESS,
  FETCH_CONTRACT_DETAILS_FAILURE,
  FETCH_LAST_RECEIPT_NUMBER_SUCCESS,
  FETCH_LAST_RECEIPT_NUMBER_FAILURE,
  FETCH_SUMMARY_REQUEST,
  FETCH_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  CLEAN_CONTRACT_DETAILS,
  SEND_PAYMENT_REQUEST,
  SEND_PAYMENT_SUCCESS,
  SEND_PAYMENT_FAILURE,
  FETCH_USER_CONTRACTS_SUCCESS,
  // eslint-disable-next-line no-unused-vars
  CLEAN_USER_CONTRACTS,
  // eslint-disable-next-line no-unused-vars
  SHOW_NO_CONTRACTS_MODAL,
  CLOSE_MODAL,
  ADD_RECEIPT,
  FETCH_MOVEMENTS_BY_CASHIER_REQUEST,
  FETCH_MOVEMENTS_BY_CASHIER_SUCCESS,
  FETCH_MOVEMENTS_BY_CASHIER_FAILURE,
  FETCH_ALL_TICKETS_REQUEST,
  FETCH_ALL_TICKETS_SUCCESS,
  FETCH_ALL_TICKETS_FAILURE,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAILURE,
  CONTRACT_DETAIL_REQUEST,
  CONTRACT_DETAIL_SUCCESS,
  CONTRACT_DETAIL_FAILURE,
  FETCH_ASSIGNED_TASKS_REQUEST,
  FETCH_ASSIGNED_TASKS_SUCCESS,
  FETCH_ASSIGNED_TASKS_FAILURE,
  UPDATE_CONTRACT_REQUEST,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_FAILURE,
  UPDATE_CONTRACT_AND_IMAGES_SUCCESS,
  UPDATE_CONTRACT_AND_IMAGES_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_USER_INFO,
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_SUCCESS,
  CARGAR_ARTICULOS_EXITO,
  CARGAR_ARTICULOS_ERROR,
  GET_CUOTAS_REQUEST,
  GET_CUOTAS_SUCCESS,
  GET_CUOTAS_FAILURE,
  FETCH_FINANCIACIONES_REQUEST,
  FETCH_FINANCIACIONES_SUCCESS,
  FETCH_FINANCIACIONES_FAILURE,
  FETCH_BILLS_REQUEST,
  FETCH_BILLS_SUCCESS,
  FETCH_BILLS_FAILURE,
  GET_PROMOS_REQUEST,
  GET_PROMOS_SUCCESS,
  GET_PROMOS_FAILURE,
  FETCH_TICKET_BY_ID_REQUEST,
  FETCH_TICKET_BY_ID_SUCCESS,

} from "./actions-types";

export const signInUser = (token, user) => ({
  type: SIGNIN_USER,
  payload: { token, user },
});

export const userInfo = (input) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}/authorization/login`, input);

    if (
      response.data.data &&
      response.data.data.token &&
      response.data.data.user
    ) {
      const { token, user } = response.data.data;
      dispatch(signInUser(token, user));
    } else {
    }

    
  } catch (error) {
    console.error("Error en la solicitud:", error);
    dispatch(cleanDetail());
  }
};

export const logout = () => async (dispatch) => {
  try {
    const userLogout = { message: "User is logout" };
    dispatch({ type: LOGOUT_USER, payload: userLogout });
    dispatch(cleanDetail());
    
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/roles`);
    
    dispatch({ type: GET_USERS, payload: response.data.data.users });
  } catch (error) {
    // Manejar errores aquí
  }
};
//user por doc
export const getUserByDocument = (n_documento) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/roles/${n_documento}`);

    dispatch({ type: GET_USER_INFO, payload: data });
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};

export const createInvoice = (input) => async (dispatch) => {
  try {
    
    const { data } = await axios.post(`${BASE_URL}/contract`, input);
    dispatch({ type: CREATE_INVOICE, payload: data });
    return { success: true }; // Indica que la solicitud fue exitosa
  } catch (error) {
    return { success: false, errorMessage: error.message }; 
  }
};

export const createUser = (input) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/authorization/signup`, input);
    dispatch({ type: CREATE_USER, payload: data });
    return { success: true }; // Indica que la solicitud fue exitosa
  } catch (error) {
    
    return { success: false, status: error.response.status, errorMessage: error.response.data.message  }; 
  }
};

export const incrementNumberFact = () => ({
  type: INCREMENT_NUMBER_FACT,
});

export const cleanDetail = () => {
  try {
    return {
      type: CLEAN_DETAIL,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchContractDetailsSuccess = (combinedData) => ({
  type: FETCH_CONTRACT_DETAILS_SUCCESS,
  payload: combinedData,
});

export const fetchContractDetailsFailure = (errorMessage) => ({
  type: FETCH_CONTRACT_DETAILS_FAILURE,
  payload: errorMessage,
});

export const fetchUserContracts = async (n_documento) => {
  try {
    const response = await axios.get(`${BASE_URL}/roles/${n_documento}`);
    

    const { Contracts, name_razonSocial } = response.data.data;
   

    const activeContracts = Contracts.filter(
      (contract) => (
        contract.estado_contrato === "ACTIVO" 
       
      )
    );
    return { contracts: activeContracts, name_razonSocial };
  } catch (error) {
    console.error("Error al obtener los contratos del usuario:", error);
    return { contracts: [], name_razonSocial: null };
  }
};

export const fetchUserContractsAction = (n_documento) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/roles/${n_documento}`);
    

    const { Contracts, name_razonSocial } = response.data.data;

    // Verificar si hay contratos
    if (Contracts && Contracts.length > 0) {
      const filteredContracts = Contracts.filter(
        contract => [
        'SOLICITUD RECHAZADA',
        'EN PROMOCION',
        'SOLICITUD DE CONTRATO',
        'ESPERANDO DATOS TECNICOS',
        'PENDIENTE FIRMA DIGITAL',
        'PENDIENTE INSTALACION',
        'ACTIVO',
        'ACTIVO CONGELADO',
        'RETIRADO',
        'CORTADO',
        'ANULADO',].includes(contract.estado_contrato)
      );

      dispatch({ type: FETCH_USER_CONTRACTS_SUCCESS, payload: filteredContracts });
    } else {
      
      dispatch(showNoContractsModal());
    }
  } catch (error) {
    console.error("Error al obtener los contratos del usuario:", error);
    return { contracts: [], name_razonSocial: null };
  }
};




export const cleanUserContracts = () => ({
  type: "CLEAN_USER_CONTRACTS",
});

export const showNoContractsModal = () => ({
  type: "SHOW_NO_CONTRACTS_MODAL",
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const fetchLastReceiptNumber = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/caja`);
    const receipts = response.data.data;

    const lastReceiptNumber = receipts.reduce((maxReceipt, currentReceipt) => {
      return Math.max(maxReceipt, currentReceipt.receipt);
    }, 0);

    dispatch({
      type: FETCH_LAST_RECEIPT_NUMBER_SUCCESS,
      payload: lastReceiptNumber,
    });
  } catch (error) {
    console.error("Error al generar el número de recibo:", error);
    dispatch({
      type: FETCH_LAST_RECEIPT_NUMBER_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchContractDetails = async (n_contrato) => {
  try {
    const response = await axios.get(`${BASE_URL}/contract/${n_contrato}`);
    const { Plan, Delivery } = response.data.data;

    return {
      Plan,
      municipio: Delivery.municipio,
      direccion: Delivery.direccion,
    };
  } catch (error) {
    console.error("Error al obtener los detalles del contrato:", error);
    return {};
  }
};
export const cleanContract = () => ({
  type: CLEAN_CONTRACT_DETAILS,
});

export const fetchSummary = (n_documento) => async (dispatch) => {
  dispatch({ type: FETCH_SUMMARY_REQUEST });
  try {
    const response = await axios.get(`${BASE_URL}/summary/${n_documento}`);
    const { bills, debitNotes, creditNotes, cash, user, saldo } =
      response.data.data;

    console.log("Response data:", response.data);

    const formattedBills = formatBills(bills);
    const formattedDebitNotes = formatDebitNotes(debitNotes);
    const formattedCreditNotes = formatCreditNotes(creditNotes);
    const formattedCash = formatCash(cash);

    dispatch({
      type: FETCH_SUMMARY_SUCCESS,
      payload: {
        user,
        saldo,
        formattedBills,
        formattedDebitNotes,
        formattedCreditNotes,
        formattedCash,
      },
    });
  } catch (error) {
    dispatch({ type: FETCH_SUMMARY_FAILURE, payload: error.message });
  }
};

const formatBills = (bills) => {
  
  return bills.map((bill) => ({
    id: bill.numberI,
    type: "Bill",
    date: bill.issue_date,
    amount: bill.price,
    qrUrl: extractQRUrl(bill.qrcode),
  }));
};

const formatDebitNotes = (debitNotes) => {
 
  return debitNotes.map((debitNotes) => ({
    id: debitNotes.id,
    type: "DebitN",
    date: debitNotes.issue_date,
    amount: debitNotes.price,
    qrUrl: extractQRUrl(debitNotes.qrcode),
  }));
};

const formatCreditNotes = (creditNotes) => {
 
  return creditNotes.map((creditNotes) => ({
    id: creditNotes.id,
    type: "CreditN",
    date: creditNotes.issue_date,
    amount: creditNotes.price,
    qrUrl: extractQRUrl(creditNotes.qrcode),
  }));
};

const formatCash = (cash) => {
 

  const formattedCash = cash.map((cashItem) => ({
    type: cashItem.paymentMethod,
    date: cashItem.paymentDate,
    amount: cashItem.importe,
  }));

 
  return formattedCash;
};

const extractQRUrl = (qrcode) => {
  const matches = qrcode.match(/QRCode=(.*)/);
  return matches ? matches[1] : null;
};

export const addReceipt = (receiptData) => ({
  type: ADD_RECEIPT,
  payload: receiptData,
});

export const sendPayment = (values) => {
  return async (dispatch) => {
    dispatch({ type: SEND_PAYMENT_REQUEST });
    try {
      const response = await axios.post(`${BASE_URL}/caja`, values);
      dispatch({ type: SEND_PAYMENT_SUCCESS, payload: response.data });
      dispatch(addReceipt(response.data.newIngreso));
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      dispatch({ type: SEND_PAYMENT_FAILURE, payload: error.message });
    }
  };
};

export const fetchMovementsByCashier = (cashierName) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MOVEMENTS_BY_CASHIER_REQUEST });

    try {
      //
      console.log(
        "Solicitud al backend:",
        `${BASE_URL}/caja/porCajero/${cashierName}`
      );

      const response = await axios.get(
        `${BASE_URL}/caja/porCajero/${cashierName}`
      );

     

      dispatch({
        type: FETCH_MOVEMENTS_BY_CASHIER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error("Error al obtener los movimientos:", error);

      dispatch({
        type: FETCH_MOVEMENTS_BY_CASHIER_FAILURE,
        payload: error.message,
      });
    }
  };
};
/* 
The `FETCH_ALL_TICKETS` action is used to fetch all tickets from the backend server.
        It sends a request to the specified endpoint
        `/tickets/get-all-tickets-test` using axios GET method. Upon successful
        response, it dispatches the `FETCH_ALL_TICKETS_SUCCESS` action with the retrieved data
        as payload. If there is an error during the request, it dispatches the
        `FETCH_ALL_TICKETS_FAILURE` action with the error message as payload. 
*/
export const fetchAllTickets = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ALL_TICKETS_REQUEST });

    try {
      //
      console.log(
        "Solicitud al backend:",
        `${BASE_URL}/tickets/get-all-tickets-test`
      );

      const response = await axios.get(
        `${BASE_URL}/tickets/get-all-tickets-test`
      );

      console.log("Respuesta del backend:", response.data);

      dispatch({
        type: FETCH_ALL_TICKETS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error("Error when fetching all tickets:", error);

      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const createContractRequest = () => ({
  type: CREATE_CONTRACT_REQUEST,
});

export const createContractSuccess = (contractRoute) => ({
  type: CREATE_CONTRACT_SUCCESS,
  payload: contractRoute,
});

export const createContractFailure = (error) => ({
  type: CREATE_CONTRACT_FAILURE,
  payload: error,
});

export const createContract = (contractData) => {
  return async (dispatch) => {
    console.log('Datos del contrato que se van a enviar al backend:', contractData);
    dispatch(createContractRequest());
    try {
      const response = await axios.post(`${BASE_URL}/contract/create`, contractData);
      const contractId = response.data.n_contrato; 
      const contractRoute = `/contract/${contractId}`; 
      dispatch(createContractSuccess(contractRoute));
    } catch (error) {
      dispatch(createContractFailure(error.message));
    }
  };
};
export const updateContractRequest = () => ({
  type: UPDATE_CONTRACT_REQUEST,
});

export const updateContractSuccess = (n_contrato) => ({
  type: UPDATE_CONTRACT_SUCCESS,
  payload: n_contrato,
});

export const updateContractFailure = (error) => ({
  type: UPDATE_CONTRACT_FAILURE,
  payload: error,
});

export const updateContract = (n_contrato, contractData) => {
  return async (dispatch) => {
   
    dispatch(updateContractRequest());
    try {
      const response = await axios.put(`${BASE_URL}/contract/modificar/${n_contrato}`, contractData);
      
      
      
      dispatch(updateContractSuccess(n_contrato, response.data));
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      dispatch(updateContractFailure(error.message));
    }
    
  };
};


// para contract
export const contractDetail = (n_contrato) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CONTRACT_DETAIL_REQUEST });

      const response = await axios.get(`${BASE_URL}/contract/${n_contrato}`);
      const { init_date, Plan,
        Delivery, Vivienda, n_documento, estado_contrato, ultimo_pago,
        descuento, idStratus, caja_nap, retencion,
        marca_onu,
        mac, reporte_c_riesgo,
        estado_cp_correo,Deudas,
        id_inventory,fecha_contrato, tel1, tel2, tel3, departamento
      } = response.data.data;

      const deuda_mes_actual = Deudas?.[0]?.deuda_mes_actual || 0;
      const saldoAnterior = Deudas?.[0]?.saldoAnterior || 0;

      dispatch({
        type: CONTRACT_DETAIL_SUCCESS,
        payload: {
          n_contrato,
          init_date,
          Plan,
          municipio: Delivery.municipio,
          barrio_vereda: Delivery.barrio_vereda,
          direccion: Delivery.direccion,
          Vivienda,
          n_documento,
          estado_contrato,
          ultimo_pago,
          descuento,
          idStratus,
          caja_nap,
          retencion,
          marca_onu,
          mac,
          reporte_c_riesgo,
          estado_cp_correo,
          id_inventory,
          fecha_contrato,
          tel1,
          tel2, 
          tel3, 
          departamento,
          deuda_mes_actual,
          saldoAnterior,

          
        },
      });
    } catch (error) {
      console.error("Error al obtener los detalles del contrato:", error);
      dispatch({ type: CONTRACT_DETAIL_FAILURE, payload: error.message });
    }
  };
};
//Tareas por usuario
export const fetchAssignedTasks = (n_documento = null) => async (dispatch) => {
  dispatch({ type: FETCH_ASSIGNED_TASKS_REQUEST });
  try {
    let url = `${BASE_URL}/task/listarTareas/`;
    if (n_documento) {
      url = `${BASE_URL}/task/listarTareas/${n_documento}`;
    }
    const response = await axios.get(url);
    
    dispatch({ type: FETCH_ASSIGNED_TASKS_SUCCESS, payload: response.data });
  } catch (error) {
    console.error("Error in fetchAssignedTasks:", error);
    dispatch({ type: FETCH_ASSIGNED_TASKS_FAILURE, payload: error.message });
  }
};

export const updateContractAndImages = (n_contrato, documentFrontImage, documentBackImage, digitalSignatureImage) => {
  return async (dispatch) => {
    try {
      // Crear un objeto FormData y agregar los datos de las imágenes
      const formData = new FormData();
      formData.append('documentFrontImage', documentFrontImage);
      formData.append('documentBackImage', documentBackImage);
      formData.append('digitalSignatureImage', digitalSignatureImage);

      // Enviar la solicitud POST con los datos de FormData al servidor
      await axios.post(`${BASE_URL}/firma/${n_contrato}`, formData);

      dispatch({
        type: UPDATE_CONTRACT_AND_IMAGES_SUCCESS,
        payload: { n_contrato, documentFrontImage, documentBackImage, digitalSignatureImage } 
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CONTRACT_AND_IMAGES_ERROR,
        payload: error.message 
      });
      
      throw error;
    }
  };
};


export const changePassword = (n_documento, passwordData) => async (dispatch) => {
  try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });
      
      const { data } = await axios.put(`${BASE_URL}/roles/updatePassword/${n_documento}`, passwordData);
      
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data });
      return { success: true };
  } catch (error) {
      dispatch({
          type: CHANGE_PASSWORD_FAILURE,
          payload: error.response?.data?.message || error.message,
      });

      console.error("Error en changePassword:", error);

      return { success: false, errorMessage: error.response?.data?.message || error.message };
  }
};

export const createTicket = (ticketData) => async (dispatch) => {
  try {
      dispatch({ type: CREATE_TICKET_REQUEST });
     
      const { data } = await axios.post(`${BASE_URL}/tickets/create`, ticketData);
      
      dispatch({ type: CREATE_TICKET_SUCCESS, payload: data });
      return { success: true, data };
  } catch (error) {
      dispatch({
          type: CREATE_TICKET_FAILURE,
          payload: error.response?.data?.message || error.message,
      });

      console.error("Error en changePassword:", error);

      return { success: false, errorMessage: error.response?.data?.message || error.message };
  }
};

export const fetchTicketById = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_TICKET_BY_ID_REQUEST});
    
    const { data } = await axios.get(`${BASE_URL}/tickets/id/${id}`);
  

    dispatch({ type: FETCH_TICKET_BY_ID_SUCCESS, payload: data });

    return { success: true, data };
} catch (error) {
    dispatch({
        type: CREATE_TICKET_FAILURE,
        payload: error.response?.data?.message || error.message,
    });

    console.error("Error en changePassword:", error);

    return { success: false, errorMessage: error.response?.data?.message || error.message };
}
} 

export const cargarArticulos = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BASE_URL}/articulos`);
      dispatch({ type: CARGAR_ARTICULOS_EXITO, payload: response.data.data });
    } catch (error) {
      console.error('Error al cargar los artículos:', error);
   
       dispatch({ type: CARGAR_ARTICULOS_ERROR, payload: error.message });
    }
  };
};

export const fetchFinanciaciones = (n_contrato) => {
  return async (dispatch) => {
      dispatch({ type: FETCH_FINANCIACIONES_REQUEST });

      try {
          let response;
          if (n_contrato) {
              response = await axios.get(`${BASE_URL}/financiacion/contrato/${n_contrato}`);
          } else {
              response = await axios.get(`${BASE_URL}/financiacion/`);
          }
          dispatch({ type: FETCH_FINANCIACIONES_SUCCESS, payload: response.data });
      } catch (error) {
          dispatch({ type: FETCH_FINANCIACIONES_FAILURE, error: error.message });
      }
  };
};


export const getCuotas = (financiacionId) => {
  return async (dispatch) => {
    dispatch({ type: GET_CUOTAS_REQUEST });
    try {
      const response = await axios.get(`${BASE_URL}/financiacion/${financiacionId}`);
      
      // Agregar console.log para imprimir los datos recibidos
      dispatch({ type: GET_CUOTAS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_CUOTAS_FAILURE, payload: error.message });
    }
  };
};

const fetchBillsRequest = () => ({
  type: FETCH_BILLS_REQUEST
});

const fetchBillsSuccess = (bills) => ({
  type: FETCH_BILLS_SUCCESS,
  payload: bills
});

const fetchBillsFailure = (error) => ({
  type: FETCH_BILLS_FAILURE,
  payload: error
});

export const fetchBills = (n_documento) => {
  return async (dispatch) => {
    dispatch(fetchBillsRequest());
    try {
      const response = await axios.get(`${BASE_URL}/caja/porUser/factura/30dias/${n_documento}`);
      dispatch(fetchBillsSuccess(response.data));
    } catch (error) {
      dispatch(fetchBillsFailure(error.message));
    }
  };
};

export const getPromos = () => async (dispatch) => {
  dispatch({ type: GET_PROMOS_REQUEST });
  
  try {
    const response = await axios.get(`${BASE_URL}/promos/listar`);
    
    dispatch({ type: GET_PROMOS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_PROMOS_FAILURE, payload: error.message });
  }
};
