export const optionsPlan = [
  { value: 'PLAN BRONCE 5MG SIN IVA', label: 'PLAN BRONCE 5MG SIN IVA' },
  { value: 'PLAN PLATA 7MG SIN IVA', label: 'PLAN PLATA 7MG SIN IVA' },
  { value: 'PLAN ORO 10MG SIN IVA', label: 'PLAN ORO 10MG SIN IVA' },
  { value: 'PLAN PLATINO 20MG SIN IVA', label: 'PLAN PLATINO 20MG SIN IVA' },
  { value: 'PLAN RUBI 30MG SIN IVA', label: 'PLAN RUBI 30MG SIN IVA' },
  { value: 'PLAN ZAFIRO 50MG SIN IVA', label: 'PLAN ZAFIRO 50MG SIN IVA' },
  { value: 'PLAN ESMERALDA 100MG SIN IVA', label: 'PLAN ESMERALDA 100MG SIN IVA' },
  { value: 'PLAN DIAMANTE 200MG SIN IVA', label: 'PLAN DIAMANTE 200MG SIN IVA' },
  { value: 'PLAN CORONA 300MG SIN IVA', label: 'PLAN CORONA 300MG SIN IVA' },
  { value: 'PLAN BRONCE 5MG CON IVA', label: 'PLAN BRONCE 5MG CON IVA' },
  { value: 'PLAN PLATA 7MG CON IVA', label: 'PLAN PLATA 7MG CON IVA' },
  { value: 'PLAN ORO 10MG CON IVA', label: 'PLAN ORO 10MG CON IVA' },
  { value: 'PLAN PLATINO 20MG CON IVA', label: 'PLAN PLATINO 20MG CON IVA' },
  { value: 'PLAN RUBI 30MG CON IVA', label: 'PLAN RUBI 30MG CON IVA' },
  { value: 'PLAN ZAFIRO 50MG CON IVA', label: 'PLAN ZAFIRO 50MG CON IVA' },
  { value: 'PLAN ESMERALDA 100MG CON IVA', label: 'PLAN ESMERALDA 100MG CON IVA' },
  { value: 'PLAN DIAMANTE 200MG CON IVA', label: 'PLAN DIAMANTE 200MG CON IVA' },
  { value: 'PLAN CORONA 300MG CON IVA', label: 'PLAN CORONA 300MG CON IVA' },
  { value: 'PLAN DEDICADO 20MG SIN IVA', label: 'PLAN DEDICADO 20MG SIN IVA' },
  { value: 'PLAN DEDICADO 30MG SIN IVA', label: 'PLAN DEDICADO 30MG SIN IVA' },
  { value: 'PLAN DEDICADO 50MG SIN IVA', label: 'PLAN DEDICADO 50MG SIN IVA' },
  { value: 'PLAN DEDICADO 100MG SIN IVA', label: 'PLAN DEDICADO 100MG SIN IVA' },
  { value: 'PLAN DEDICADO 200MG SIN IVA', label: 'PLAN DEDICADO 200MG SIN IVA' },
  { value: 'PLAN DEDICADO 300MG SIN IVA', label: 'PLAN DEDICADO 300MG SIN IVA' },
  { value: 'PLAN DEDICADO 500MG SIN IVA', label: 'PLAN DEDICADO 500MG SIN IVA' },
  { value: 'PLAN DEDICADO 600MG SIN IVA', label: 'PLAN DEDICADO 600MG SIN IVA' },
  { value: 'PLAN DEDICADO 1000MG SIN IVA', label: 'PLAN DEDICADO 1000MG SIN IVA' },
  { value: 'PLAN DEDICADO 20MG CON IVA', label: 'PLAN DEDICADO 20MG CON IVA' },
  { value: 'PLAN DEDICADO 30MG CON IVA', label: 'PLAN DEDICADO 30MG CON IVA' },
  { value: 'PLAN DEDICADO 50MG CON IVA', label: 'PLAN DEDICADO 50MG CON IVA' },
  { value: 'PLAN DEDICADO 100MG CON IVA', label: 'PLAN DEDICADO 100MG CON IVA' },
  { value: 'PLAN DEDICADO 200MG CON IVA', label: 'PLAN DEDICADO 200MG CON IVA' },
  { value: 'PLAN DEDICADO 300MG CON IVA', label: 'PLAN DEDICADO 300MG CON IVA' },
  { value: 'PLAN DEDICADO 500MG CON IVA', label: 'PLAN DEDICADO 500MG CON IVA' },
  { value: 'PLAN DEDICADO 600MG CON IVA', label: 'PLAN DEDICADO 600MG CON IVA' },
  { value: 'PLAN DEDICADO 1000MG CON IVA', label: 'PLAN DEDICADO 1000MG CON IVA' },


  ];
  export const optionsEstrato = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];
  export const optionsZona = [
    { value: 'Urbana', label: 'Urbana' },
    { value: 'Rural', label: 'Rural' },
  ];   
  
  export const optionsVivienda = [
    { value: '1', label: 'Alquilada' },
    { value: '2', label: 'Propia' },
    { value: '3', label: 'Familiar' },
    { value: '4', label: 'Tienda' },
    { value: '5', label: 'Instituciones' },
    { value: '6', label: 'Edificio' },
    { value: '7', label: 'Hostal/Hotel' },
    { value: '9', label: 'Finca' },
    { value: '10', label: 'Cabaña' },

  ];   

  export const optionsMunicipio = [
    { value: 'Restrepo', label: 'RESTREPO' },
    { value: 'Cumaral', label: 'CUMARAL' },
  ];   

  export const optionsFacturaDigital =[
    {value: true , label : "Si" },
    {value: false , label : "No" },
  ];
  export const optionsCalle = [
    { value: '1', label: 'Vereda' },
    { value: '2', label: 'Avenida' },
    { value: '3', label: 'Calle' },
    { value: '4', label: 'Carrera' },
    { value: '5', label: 'Finca' },
    { value: '6', label: 'Otro' },
  ];

  export const optionsRiesgo =[
    {value: true , label : "Si" },
    {value: false , label : "No" },
  ];

  export const optionsEstadoContrato = [
          {value: 'SOLICITUD RECHAZADA', label :'SOLICITUD RECHAZADA'},
          {value:'PENDIENTE FIRMA DIGITAL', label :'PENDIENTE FIRMA DIGITAL'}
  ]