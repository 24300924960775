import React from 'react';
import Logo from '../../assets/Logo/Logo.jpg';
import './Navbar.css';

function NavbarSignin() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={Logo} alt="Logo de la compañía" className="logo-image" />
      </div>
    </nav>
  );
}

export default NavbarSignin;
