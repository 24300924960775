import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import "./MovementsDetail.css";
import { fetchMovementsByCashier } from "../../../Redux/Actions/actions";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& > *": {
      marginRight: theme.spacing(2),
      color: "white",
    },
  },
  container: {
    marginTop: theme.spacing(8), // Margen superior
    margin: theme.spacing(2), // Margen a los costados
  },
}));

const MovementsDetail = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const dispatch = useDispatch();
  const userName = useSelector(
    (state) => state.authentication.user.razon_social
  );
  const movements = useSelector((state) => state.data);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("Usuario autenticado:", userName);
      dispatch(fetchMovementsByCashier(userName));
    }
  }, [isAuthenticated, userName, dispatch]);

  useEffect(() => {
    console.log("Movements:", movements);
  }, [movements]);

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  const filteredMovements = movements
    ? movements.filter((movement) => movement.paymentDate === formattedDate)
    : [];
  const sumByPaymentMethod = filteredMovements.reduce((acc, movement) => {
    acc[movement.paymentMethod] =
      (acc[movement.paymentMethod] || 0) + movement.importe;
    return acc;
  }, {});

  return (
    <Grid className={classes.container}>
      <Grid className={classes.buttonGroup}>
        <Button
          variant="contained"
          style={{
            background: "#206482",
            marginRight: "10px",
            marginTop: "30px",
            padding: "10px",
          }}
          type="button"
          onClick={() => history.push("/caja")}
        >
          Volver
        </Button>
      </Grid>
      {filteredMovements.length > 0 ? (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Detalles de Movimientos del Cajero
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Fecha: {formattedDate}
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <TableContainer component={Paper} className="table-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header-cell">
                      Número de Recibo
                    </TableCell>
                    <TableCell className="table-header-cell">
                      Fecha de Pago
                    </TableCell>
                    <TableCell className="table-header-cell">Usuario</TableCell>
                    <TableCell className="table-header-cell">Importe</TableCell>
                    <TableCell className="table-header-cell">
                      Contrato
                    </TableCell>
                    <TableCell className="table-header-cell">
                      Método de Pago
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMovements.map((movement) => (
                    <TableRow key={movement.receipt}>
                      <TableCell>{movement.receipt}</TableCell>
                      <TableCell>{movement.paymentDate}</TableCell>
                      <TableCell>{movement.username}</TableCell>
                      <TableCell>{movement.importe}</TableCell>
                      <TableCell>{movement.contract}</TableCell>
                      <TableCell>{movement.paymentMethod}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid className="summary">
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Total por tipo de pago
            </Typography>
            <ol style={{ listStyleType: "none" }}>
              {Object.keys(sumByPaymentMethod).map((paymentMethod) => (
                <li
                  key={paymentMethod}
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {paymentMethod}: {sumByPaymentMethod[paymentMethod]}
                </li>
              ))}
            </ol>
          </Grid>
        </>
      ) : (
        <Typography variant="body1">
          No hay movimientos disponibles para hoy.
        </Typography>
      )}
    </Grid>
  );
};

export default MovementsDetail;
