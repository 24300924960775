import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from "../assets/Logo/12.png";; // Actualiza la ruta a tu logo


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    logo: {
        width: 100,
        height: 'auto'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    text: {
        fontSize: 12,
        marginBottom: 10
    }
});

const ReceiptPDF = ({ receiptData }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <Image style={styles.logo} src={Logo} />
                <Text style={styles.text}>Número de Recibo: {receiptData.receipt}</Text>
            </View>
            <View style={styles.section}>
                <Text style={styles.text}>Fecha de Pago: {receiptData.paymentDate}</Text>
                <Text style={styles.text}>Hora: {receiptData.paymentTime}</Text>
                <Text style={styles.text}>Usuario: {receiptData.username}</Text>
                <Text style={styles.text}>Descripción: {receiptData.description}</Text>
                <Text style={styles.text}>Importe: {receiptData.importe}</Text>
                <Text style={styles.text}>Método de Pago: {receiptData.paymentMethod}</Text>
                <Text style={styles.text}>Cajero: {receiptData.cashierName}</Text>
                <Text style={styles.text}>Contrato N°: {receiptData.contract}</Text>
            </View>
        </Page>
    </Document>
);

export default ReceiptPDF;