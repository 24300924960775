import React, { useEffect, useState } from "react";
import {PDFDownloadLink, Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import {
  contractDetail,
  getUsers,
  updateContract,
  
} from "../../Redux/Actions/actions";
import { useParams } from "react-router-dom";
import axios from "axios";
import {BASE_URL} from "../../Config";
import { useHistory } from "react-router-dom";
import { Grid, Checkbox } from "@material-ui/core";

const PDFGenerator = () => {
  const history = useHistory();
  const [costoArticulo, setCostoArticulo] = useState(null);
  const dispatch = useDispatch();
  const { n_contrato } = useParams();
  const contractDetails = useSelector((state) => state.contractDetails);
  const usersData = useSelector((state) => state.usersData);
  console.log("Valor contractDetails:", contractDetails);

  const [mesActual, setMesActual] = useState(null);
  const [anioActual, setAnioActual] = useState(null);
  const [diaContrato, setDiaContrato] = useState(null);
  const [mesContrato, setMesContrato] = useState(null);
  const [anioContrato, setAnioContrato] = useState(null);
  const [numeroPlan, setNumeroPlan] = useState(() => {
    return null;
  });

  useEffect(() => {
    dispatch(contractDetail(n_contrato));
    dispatch(getUsers());

    const obtenerCostoArticulo = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/articulos`);
        const articulos = response.data.data;

        let codigoDataico;
        if (
          contractDetails.idStratus === "1" ||
          contractDetails.idStratus === "2" ||
          contractDetails.idStratus === "3"
        ) {
          codigoDataico = "207";
        } else if (
          contractDetails.idStratus === "4" ||
          contractDetails.idStratus === "5" ||
          contractDetails.idStratus === "6" ||
          contractDetails.idStratus === "7"
        ) {
          codigoDataico = "208";
        } else {
          codigoDataico = "207";
        }

        const articulo = articulos.find(
          (articulo) => articulo.codigoDataico === codigoDataico
        );
        if (articulo) {
          setCostoArticulo(articulo.costo);
        }
      } catch (error) {
        console.error("Error al obtener el costo del artículo:", error);
      }
    };
    const fechaContrato = new Date(contractDetails.fecha_contrato);
    const diaContrato = fechaContrato.getDate();
    const mesContrato = fechaContrato.getMonth() + 1;
    const anioContrato = fechaContrato.getFullYear();
    const fechaActual = new Date();
    const mes = fechaActual.getMonth() + 1;
    const anio = fechaActual.getFullYear();

    if (
      contractDetails &&
      contractDetails.Plan &&
      contractDetails.Plan.name_plan
    ) {
      const namePlan = contractDetails.Plan.name_plan;
      const numeroPlan = namePlan.match(/\d+/)[0];
      setNumeroPlan(numeroPlan);
      console.log("Numero del plan:", namePlan);
    }

    setMesActual(mes);
    setAnioActual(anio);
    setNumeroPlan(numeroPlan);

    setDiaContrato(diaContrato);
    setMesContrato(mesContrato);
    setAnioContrato(anioContrato);

    obtenerCostoArticulo();
  }, []);


  let nombreRazonSocial = ""; 

if (usersData) {
  // Verificar si usersData está disponible
  const user = usersData.find(
    (user) => user.n_documento === contractDetails.n_documento
  );
  if (user) {
    nombreRazonSocial = user.name_razonSocial; 
    console.log("Nombre o Razón Social:", nombreRazonSocial); 
  } else {
    console.log(
      "No se encontró ningún usuario para los detalles del contrato:",
      contractDetails
    );
  }
} else {
  console.log("No hay datos de usuarios disponibles");
}

  const handleDigitalSignature = async () => {
    try {
      const updatedContractData = {
        init_date: new Date(),
      };

      await dispatch(
        updateContract(n_contrato, updatedContractData)
      );
      
      history.push(`/firma/${n_contrato}`);
    } catch (error) {
      console.error("Error al actualizar el contrato:", error);
    }
  };

  const contratoContent = (
    <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.rightAlignedText}>
        Número de Contrato: {contractDetails.n_contrato}
      </Text>
      <Grid>
        <View style={styles.container}>
          <Text style={styles.title}>
            FHANRED S.A.S, Nit. No. 901764347-7
          </Text>
          <Text style={styles.title2}>
            CONTRATO ÚNICO DE PRESTACION DE SERVICIOS FIJOS DE
            TELECOMUNICACIONES
          </Text>
          <Text style={styles.title3}>Registro Tic No 96008577</Text>
        </View>
      </Grid>
      <View>
        <Text style={styles.text}>
          Condiciones del Contrato para la prestación de los servicios fijos
          de telecomunicaciones entre:{" "}
          {nombreRazonSocial}
          {"\n"}y FHANRED S.A.S, con Nit. 901764347-7-, por el que pagará
          mínimo mensualmente la suma de ${contractDetails && contractDetails.Plan && contractDetails.Plan.costo}, así
          como un valor de instalación de ${costoArticulo}. Este contrato
          tendrá vigencia de 12 meses, contados a partir del {diaContrato},
          del mes {mesContrato}
          {"\n"}, del año {anioContrato}.{"\n"}
        </Text>
        <Grid>
          <Text style={styles.title4}>
            EL PLAZO MÁXIMO DE INSTALACIÓN ES DE 15 DÍAS HÁBILES.
          </Text>
        </Grid>
        <Grid>
          <Text style={styles.text}>
            Acepto de manera voluntaria que mi contrato se renueve sucesiva
            y automáticamente por un plazo igual a la inicial
          </Text>
        </Grid>
        <Grid>
          <Text style={styles.text}>
            EL SERVICIO. Con este contrato en FHANRED S.A.S. como compañía
            nos comprometemos a prestarle los servicios de:
          </Text>
        </Grid>
        <Grid>
          <Text style={styles.text}>Internet Fijo </Text>
        </Grid>
        <Grid>
          <Text style={styles.text}>
            Usted se compromete a pagar oportunamente dentro del plazo
            establecido el precio acordado.{" "}
          </Text>
        </Grid>

        <Grid>
          <Text style={styles.title4}>DATOS DEL SUSCRIPTOR</Text>
        </Grid>

        <Grid>
          <Text style={styles.text}>
            Numero de Contrato: {contractDetails && contractDetails.n_contrato} Nombre o razón
            social: {nombreRazonSocial}
            Identificación: {} {} Correo electrónico: {}
            {"\n"} Teléfono de contacto 1: {contractDetails && contractDetails.tel1} ____
            Teléfono de contacto 2:
            {contractDetails && contractDetails.tel2}____Teléfono de contacto 3:{" "}
            {contractDetails && contractDetails.tel3}_____ Estrato: {contractDetails && contractDetails.idStratus}{" "}
            ___ Dirección de residencia: {contractDetails && contractDetails.direccion}
            ________Municipio: {contractDetails && contractDetails.municipio}
            ______ Departamento: {contractDetails && contractDetails.municipio}
          </Text>
        </Grid>
        <Grid>
          <Text style={styles.title4}>
            CONDICIONES COMERCIALES CARACTERISTICAS DE PLAN:{" "}
            {contractDetails && contractDetails.Plan && contractDetails.Plan.name_plan}
            Valor: ${contractDetails && contractDetails.Plan && contractDetails.Plan.costo}
            __ Paquete Promocional ______ (DUO) __AFILIACION: ____________{" "}
          </Text>
        </Grid>

        <Grid>
          <Text style={styles.text}>
            Permanencia Mínima SI _____ NO ______ OBJETO DEL CONTRATO. El
            presente contrato tiene por objeto la prestación remunerada de
            servicios públicos de televisión cableada por suscripción o el
            servicio de internet o ambos, en modalidad de empaquetamiento
            denominado DUO, por cualquier tecnología o medio tecnológico por
            parte del OPERADOR y a favor del SUSCRIPTOR y/o CLIENTE, según
            lo pactado en el presente contrato, los documentos que hacen
            parte integral del mismo y conforme las disposiciones que
            regulan la materia, principalmente las emanadas por parte del
            Ministerio de Tecnologías de la Información, de la Comisión de
            Regulación de Comunicaciones (CRC) y de la Superintendencia de
            Industria y Comercio (SIC); también de conformidad con los
            distintos mandatos que regulan la protección al consumidor en
            Colombia (en particular la Ley 1480 de 2011 ymodificatorias o
            complementarias), las normas relativas a la protección y
            conservación de datos personales y demás que por su contenido
            interesen o afecten directa o indirectamente a la presente
            relación contractual la resolución CRC 4930 de 2016, la ley 5111
            de 2017 y la ley 1978 de 2019“Por la cual se moderniza el Sector
            de las Tecnologías de la Información y las Comunicaciones -TIC,
            se distribuyen competencias, se crea un Regulador Único y se
            dictan otras disposiciones”. PARÁGRAFO 1: El consentimiento
            requerido para la validez y eficacia del presente contrato se
            perfecciona con la firma del SUSCRIPTOR impuesta en la parte
            final de este documento, con lo cual se dan por aceptados tanto
            el contenido del presente clausulado como la caracterización
            delservicio contratado y el contenido de todos los documentos
            que hacen parte integral del presente acto jurídico. PARÁGRAFO
            2: El presente contrato rige las relaciones contractuales entre
            las partes relativas a la prestación del servicio de televisión
            por suscripción, internet, uno de ellos o ambos; tratándose de
            la prestación de lo s servicios de comunicaciones, el clausulado
            respectivo podrá ser consultado en la página web
            ________________________________ DESCRIPCION DEL SERVICIO
            PRESTADO. El OPERADOR prestará al SUSCRIPTOR el servicio de
            Televisión Cableada o satelital por Suscripción o a través de
            fibra óptica o internet, según las condiciones especificadas en
            la Carátula Principal, considerando que la oferta de canales
            será general, abierta, variada y flexible, con diversos
            contenidos de entretenimiento y varios niveles de información;
            en todo tiempo será posible para el OPERADOR introducir cambios
            en la oferta de canales (incrementándolos, No. disminuyéndolos o
            sustituyéndolos) manteniendo en todo caso la caracterización de
            la oferta general contratada: El OPERADOR se reserva el derecho
            de ampliar el número de canales cuando considere necesario, lo
            que podrá generar incremento en el valor del contrato que
            asumirá el SUSCRIPTOR desde el momento en que reciba las nuevas
            señales por suscripción. También podrá modificar las tarifas en
            cualquier momento, sin que los incrementos puedan en ningún caso
            superar el monto de las tarifas máximas registradas por parte
            del OPERADOR ante el MINISTERIO DE TECNOLOGIAS DE LA INFORMACION
            Y LAS COMUNICACIONES (MINTIC). En lo que respecto en la
            modalidad de servicio denominado pague por ver (PPV) o video
            bajo demanda (VBD) este tendrá otras condiciones y precios
            adicionales. PRINCIPALES OBLIGACIONES DEL USUARIO. 1. Pagar
            oportunamente los servicios prestados, incluyendo los intereses
            de mora cuando haya incumplimiento. 2. Suministrar Información
            verdadera. 3. Hacer uso adecuado de los equipos y servicios. 4.
            No divulgar pornografía infantil a través de su red. 5. Avisar a
            las autoridades cualquier evento de robo o hurto de elementos de
            la red, como el cable. La ONU propiedad de la empresa. 6. No
            cometer o ser partícipe de actividades de fraude. 7. No extender
            las señales a otras unidades de vivienda o de comercio distintas
            a la establecida en el presente contrato. 8. Dar aviso oportuno
            a la empresa cuando detecte conexiones fraudulentas de terceros
            particulares. 9. Cumplir las obligaciones pactadas en este
            contrato. .10. Reportar al OPERADOR inmediatamente ocurra el
            hurto o extravío de su equipo o. CALIDAD Y COMPENSACIÓN. Cuando
            se presente indisponibilidad del servicio o este se suspenda a
            pesar de su pago oportuno, por más de 12 horas, será compensado
            en su próxima factura. Debemos cumplir con las condiciones de
            calidad definidas por la CRC. Consulte las en la página
            www.operador.com/indicadores de calidad. Resolución 5111 de
            2017. Así mismo dando cumplimiento a lo ordenado por el MINTIC,
            la CRC y la SIC, el OPERADOR tendrá permanentemente a
            disposición del SUSCRIPTOR líneas de atención para las
            Peticiones, Quejas o Reclamos (PQR) a través de su línea
            telefónica No. 3135264600 correo electrónico
            ____________________________________o en su página
            WEB____________________________________, donde serán atendidas y
            se les dará el trámite previsto en la reglamentación de la CRC y
            la SIC. CESIÓN. Si quieres ceder este contrato a otra persona,
            debe presentar una solicitud por escrito a través de nuestros
            medios de atención, acompañada de la aceptación por escrito de
            la persona a la que se le hará la cesión. Dentro de los 15 días
            hábiles siguientes, analizaremos su solicitud y le daremos una
            respuesta. Si se acepta la sesión queda liberado de cualquier
            responsabilidad con nosotros. MODIFICACIÓN. Nosotros EL OPERADOR
            no podemos modificar el contrato sobre los servicios prestados
            sin su autorización. Esto incluye que no podemos cobrarle
            servicios que no haya aceptado expresamente. Si Esto ocurre
            tiene derecho a terminar el contrato, incluso Estando vigente la
            cláusula de permanencia mínima, sin la obligación de pagar suma
            alguna por este concepto. No obstante, usted puede en cualquier
            momento modificar los servicios contratados. Dicha modificación
            se hará efectiva en el periodo de facturación siguiente, para lo
            cual deberá presentar la solicitud de modificación por lo menos
            con tres (3) días hábiles de anterioridad al corte de
            facturación. SUSPENSIÓN. Usted tiene derecho a solicitar la
            suspensión del servicio por un máximo de 2 meses al año, sin
            costo alguno. Para esto debe estar al día con su factura
            presentar la solicitud antes del inicio del ciclo de facturación
            que desea suspender. Sí existe una cláusula de permanencia
            mínima, su vigencia se prorrogará por el tiempo que dure la
            suspensión, cuando esta termine se reanudará el termino de
            permanencia mínima pactado. TERMINACIÓN. Usted puede terminar el
            contrato en cualquier momento sin penalidades. Para esto debe
            realizar una solicitud a través de cualquier de nuestros medios
            de atención al público, identificándose plenamente con su
            fotocopia de la cedula, mínimo tres (5) días hábiles antes del
            corte de facturación (su corte de facturación es el 30 de cada
            mes) y debe estar al día con su factura. Si presenta la
            solicitud con una anticipación menor a los 5 días del
            vencimiento, la terminación del servicio se dará en el siguiente
            periodo de facturación. Así mismo, usted puede cancelar
            cualquiera de los servicios contratados, para lo que le
            informaremos las condiciones en las que serán prestados los
            servicios no cancelados y actualizaremos el contrato, para los
            servicios no cancelados. Así mismo, si el operador no inicia la
            prestación del servicio en el plazo acordado, usted puede pedir
            la restitución de su dinero y la terminación del contrato.
            PARAGRAFO. Si el OPERADOR a fin de prestarle y llevarle sus
            servicios a su domicilio, debe asumir costos como la ONU,
            decodificadores y controles, estos serán entregados en COMODATO
            o préstamo de uso al SUSCRIPTOR, el cual a la terminación del
            contrato deberá devolverlos en buen estado, si esto no ocurre
            así, le serán cobrados al SUSCRIPTOR, si este no cancela el
            valor del (os) equipo (s) será tenido y reportado a las bases de
            datos como deudor moroso, independientemente y sin perjuicio de
            las demás acciones jurídicas que se puedan iniciar en su contra,
            una vez cancele esta penalidad, será excluido su nombre de los
            registros y reportes de las bases de riesgos. El valor del
            equipamiento será; ONU $ 300.000. PAGO Y FACTURACIÓN. La factura
            le debe llegar como mínimo los cinco (5) primeros días hábiles
            de cada mes con la fecha de pago oportuno. Si no le llega, puede
            solicitarla a través de nuestros medios de atención físico o
            virtual y debe pagarla oportunamente, en la misma se incorporara
            la fecha límite de pago, transcurrido ese término y de no
            registrarse el pago, su servicio será suspendido y se cobrara la
            reconexión. El periodo de facturación corresponde al mes actual
            (del día 1 a 30 de cada mes), en la cual el suscriptor recibirá
            los servicios, es decir se facturará y cobrará por
            reglamentación de las autoridades, el servicio anticipado del
            mes servido para su pago antes de la fecha límite de pago del
            mes correspondiente. El no recibido de la factura no exime su
            pago oportuno. Si no paga a tiempo, previo aviso, suspenderemos
            su servicio hasta que pague sus saldos pendientes. Contamos con
            3 días hábiles luego de su pago para reconectarle el servicio.
            Si no paga a tiempo, cobrará el valor de la reconexión,
            igualmente al usuario autoriza al OPERADOR a que reporte las
            deudas impagadas a las centrales de riesgo o bases de datos.
            Para esto de acuerdo con lo ordenado por la SIC la CRC y el
            MINTIC, se le dará aviso al usuario con no menos de veinte (20)
            días calendario de anticipación a su reporte. Si paga luego de
            este reporte tenemos la obligación dentro del mes de seguimiento
            de informar su pago para que ya no aparezca reportado en la
            central de riesgo. Si tiene un reclamo sobre su factura, puede
            presentarlo antes de la fecha de pago y en ese caso no debe
            pagar las sumas reclamadas hasta que resolvamos su solicitud. Si
            ya pago, tiene 6 meses para presentar la reclamación. FHANRED
            S.A.S. -, se reserva el derecho de ampliar el número de canales
            e incrementar el valor del servicio contratado, desde que el
            SUSCRIPTOR reciba las señales nuevas. Tal incremento deberá
            respetar los topes tarifarios registrado en MINTIC. Los
            incrementos se harán conforme al índice de precios al consumidor
            de cada año a partir del 1 de enero de cada año. FHANRED SAS -
            podrá introducir cambios en la parrilla de Programación sin
            disminuir en ningún caso la calidad de la oferta general
            inicialmente pactada y contratada. COMO COMUNICARSE CON NOSOTROS
            (MEDIOS DE ATENCIÓN). 1. Nuestros medios de atención son:
            oficinas físicas, página web, redes sociales y líneas
            telefónicas. 2. Presente cualquier queja, petición/reclamo o
            recurso a través de estos medios y le responderemos en un máximo
            de 15 días hábiles. 3. Si no respondemos es porque aceptamos su
            petición o reclamo. Esto se llama silencio administrativo
            positivo y aplica para internet y televisión. Si no está de
            acuerdo con nuestra respuesta, cuando su queja o petición sea
            por los servicios de internet o televisión y esté relacionada
            con actos de negativa del contrato, suspensión del servicio,
            terminación del contrato, corte y facturación; usted puede
            interponer el recurso de reposición ante el OPERADOR y si no
            llegamos a una solución satisfactoria para usted, puede enviamos
            su reclamo directamente al OPERADOR o a la SIC (superintendencia
            de industria y comercio) quien resolverá de manera definitiva su
            solicitud. Esto se llama recurso de reposición y en subsidio
            apelación. Cuando su queja o petición sea por el servicio de
            televisión, puede enviar la misma al Ministerio de
            Comunicaciones, para que esta entidad resuelva su solicitud o la
            misma SIC. CAMBIO DE DOMICILIO. Usted puede cambiar de domicilio
            y continuar con el servicio siempre que sea técnicamente posible
            su traslado del servicio. Si desde el punto de vista técnico no
            es viable el traslado del servicio, usted puede terminar, ceder
            su contrato a un tercero o terminarlo, siempre y cuando la
            cláusula de permanencia esté vigente, en caso tal deberá
            cancelar la penalidad impuesta. COBRO POR RECONEXIÓN DEL
            SERVICIO. En caso de suspensión del servicio por mora en el
            pago, podremos cobrarle un valor de reconexión que corresponderá
            estrictamente a los costos asociados a la operación de
            reconexión. En caso de servicios empaquetados procede máximo un
            cobro de reconexión por cada tipo de conexión empleado en la
            prestación de los servicios. Costo reconexión televisión $
            ____________ , Internet $ 16.000 Paquete DUO $ __________. El
            usuario es el único responsable por el contenido y la
            información que se curse a través de la red y del uso que se
            haga de los equipos o de los servicios. Los equipos de
            comunicaciones que ya no use son desechos que no deben ser
            botados a la caneca, consulte nuestra política de recolección de
            aparatos en desuso. INSTALACIÓN, OPERACIÓN Y MANTENIMIENTO DE
            ELEMENTOS NECESARIOS: FHANRED SAS - se ocupará, directa o
            indirectamente, de las acciones referidas en el asunto de esta
            cláusula, para la recepción del servicio contratado. EL
            SUSCRIPTOR acepta expresamente las condiciones estándar de
            cableado usado por EL OPERADOR en sus instalaciones y acepta
            cancelar el valor del material adicional en los casos
            excepcionales que se requieran, aclarando que la instalación de
            la red para el servicio incluye un largo total de hasta 150
            metros sin que esto incluya el material decorativo el cual se
            facturara adicionalmente y por separado. La instalación normal
            se realizará máximo a dos (2) receptores de televisión que
            tengan entrada de RF y para los puntos adicionales se facturará
            el valor de la derivación correspondiente el cual tendrá un
            coste de $ _______, por daca punto. Para el internet EL OPERADOR
            - entrega un equipo tipo ONU el cuál se entrega en calidad de
            comodato o préstamo de uso y el suscriptor deberá devolverlo en
            perfecto estado al Operador una vez se dé por terminado el
            contrato actual. De No devolver LA ONU o si el equipo sufre
            algún daño a causa de suscriptor o lo extravía, pagara al
            Operador la suma de $300.000 SUSCRIPTOR no manipulara, alterara,
            modificara la red, los equipos y accesorios de su instalación,
            dado que esto puede generar interrupciones del servicio, lo cual
            en el caso que ocurra se faculta al operador a facturar el costo
            de las reparaciones y nuevos materiales empleados en la atención
            de la falla a cargo del Suscriptor. FHANRED SAS - no realiza
            ninguna devolución en efectivo de los dineros recibidos por la
            suscripción o por las mensualidades canceladas por los servicios
            contratados. El suscriptor ha recibido el listado de canales
            elegidos, así como copia del presente contrato. ANEXO. EL
            suscriptor se compromete a 1. OBLIGACIONES. No dar al servicio
            de acceso a internet un uso inadecuado o contrario a las normas
            legales, el orden público o las buenas costumbres y en especial
            acatar las normas establecidas en la ley 679 de 2001 y sus
            decretos reglamentarios, el código penal y la ley, teniendo en
            cuenta que su cumplimiento acarreará las sanciones
            administrativas y penales allí contempladas que a bien tuviere
            imponerle la autoridad competente. En tal medida, deberá
            abstenerse de: Alojar en su propio sitio imágenes, textos,
            documentos o archivos audiovisuales que impliquen directa o
            indirectamente actividades sexuales con menores de edad;
            Material pornográfico en especial en modo de imágenes o video
            cuando existan indicios de que las personas fotografiadas o
            filmadas son menores de edad; Vínculos o links sobre sitios
            temáticos que contengan o distribuyan material relativo a
            menores de edad. FHANRED SAS - durante la vigencia del presente
            contrato, podrá verificar el cumplimiento, podrá retirar del
            servicio aquella información que estime pertinente y/o suspender
            la prestación del servicio. Sin perjuicio de lo anterior FHANRED
            SAS no será responsable ante EL SUSCRIPTOR o terceros por el
            contenido de la información recibida a través del servicio de
            acceso a internet. 1. A atender las instrucciones del OPERADOR
            -, para el uso adecuado del servicio. 1.1. Informar al OPERADOR
            cualquier irregularidad que se presente en la prestación del
            servicio. 2. DEBERES. 2.1. Denunciar ante las autoridades
            competentes cualquier acto criminal, que tenga conocimiento, En
            contra de los menores de edad, incluso de la difusión de
            material pornográfico asociado a menores. 2.2. Combatir con
            todos los medios técnicos a su alcance la difusión de material
            pornográfico con menores de edad. 2.3. Abstenerse de usar redes
            globales de información para la divulgación de material ilegal
            con menores de edad. 2.4. Establecer mecanismos técnicos de
            bloqueo por medio de los cuales se puedan proteger a sí mismo o
            a sus hijos del material ilegal, ofensivo o indeseable en
            relación con menores de edad. Obligaciones servicio de internet.
            Con relación al servicio de internet por la red de alta
            velocidad, EL OPERADOR se reserva la potestad de elegir la
            tecnología FTTH por medio de la cual técnicamente se prestará el
            servicio. Por tratarse de una obligación de medio y no de
            resultado FHANRED SAS no garantiza la continuidad del servicio
            ni será responsable por los perjuicios que se causen con ocasión
            de la interrupción del mismo. Sin perjuicio de lo anterior EL
            OPERADOR - será responsable de las fallas que les sean
            imputables. 2. Medir los consumos equivalentes al tiempo de
            conexión a la red con los procedimientos tecnológicos
            apropiados. MERITO EJECUTIVO. El presente documento por contener
            obligaciones claras, expresas y actualmente exigibles, presta
            mérito como título ejecutivo exigibles ante los jueces de la
            Republica, en caso de incumplimiento total o parcial de alguna
            de las partes en sus obligaciones, facultando al OPERADOR a
            iniciar el cobro respectivo de la obligación incumplida, sin
            perjuicios de las demás acciones que pueda que pueda adelantar
            en contra del usuario.{" "}
          </Text>
        </Grid>
        <Grid>
          <Text style={styles.title4}>
            Acepto las cláusulas y condiciones indicadas en este documento,
          </Text>
        </Grid>
        <Grid>
          <Text style={styles.title4}>
            CC/CE/NIT___{} {}
          </Text>
        </Grid>

        <Grid>
          <Text style={styles.title4}>
            Firmado el día -{diaContrato}- del mes -{mesContrato}-, -del año
            -{anioContrato}-
          </Text>
        </Grid>
      </View>
    </Page>
  </Document>
  );

  return (
    <Grid className="container">
    {/* Mostrar contenido del contrato en pantalla */}
    {contratoContent}

    {/* Botón de descarga del PDF */}
    <PDFDownloadLink
      document={contratoContent}
      fileName="contrato.pdf" // Nombre del archivo PDF
    >
      {({ blob, url, loading, error }) => 
        loading ? 'Cargando documento...' : 'Descargar PDF'
      }
    </PDFDownloadLink>

    <Grid style={{ marginTop: "20px" }}>
      <Checkbox type="checkbox" id="terms" />
      <label htmlFor="terms">Acepto los términos y condiciones</label>
    </Grid>
    <Grid style={{ marginTop: "20px" }}>
      <button onClick={handleDigitalSignature}>Firma Digital</button>
    </Grid>
  </Grid>
);
};

export default PDFGenerator;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    padding: 1,
    marginTop: 10,
  },
  container: {
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 10,
    display: "flex",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 6,
    marginLeft: 160,
  },
  title2: {
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 15,
    marginBottom: 10,
    marginLeft: 60,
  },
  title3: {
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 3,
    marginBottom: 10,
    marginLeft: 250,
  },
  title4: {
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 0,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    marginTop: 5,
  },
  rightAlignedText: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 580,
  },
});
