import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { TfiPowerOff } from 'react-icons/tfi';
import Logo from '../../assets/Logo/Logo.jpg';
import './Navbar.css';
import { logout } from '../../Redux/Actions/actions';
import AppMenu from '../MenuDesplegable/MenuDesplegable';

function Navbar() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const userName = useSelector((state) => state.authentication.user?.razon_social ?? "Usuario");
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={Logo} alt="Logo de la compañía" className="logo-image" />
      </div>

      <div className="user-info">
        <FaUserCircle />
        <span>{userName}</span>
      </div>

      <div>
        <AppMenu />
      </div>

      <div className="logout-icon" title="Cerrar Sesión" onClick={handleLogout}>
        <TfiPowerOff />
      </div>
    </nav>
  );
}

export default Navbar;

