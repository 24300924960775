

export const BASE_URL =  'https://fhanred-1.onrender.com'
export const URL_FRONT = 'https://fhanred.com';
export const URL_DASH = 'https://control.fhanred.com';



const Config = {
    BASE_URL,
    URL_FRONT,
    URL_DASH,
};

export default Config;