import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import marker2 from '../assets/marker2.svg';
import "leaflet/dist/leaflet.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const customMarkerIcon = L.icon({
    iconUrl: marker2,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
});

function MapView({ onCoordinatesChange, onSave }) {
    const [position, setPosition] = useState([4.273056, -73.486389]);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMarkerMove = (e) => {
        const latlng = e.target.getLatLng();
        setPosition([latlng.lat, latlng.lng]);
        onCoordinatesChange([latlng.lat, latlng.lng]);
    };

    const handleSave = () => {
        onSave(position);
        MySwal.fire({
            title: 'Coordenadas guardadas',
            text: 'Ahora completa los campos',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    };


    const handleMouseOver = () => {
        setShowTooltip(true);
    };

    const handleMouseOut = () => {
        setShowTooltip(false);
    };

    return (
        <MapContainer center={position} zoom={13} style={{ height: "400px", width: "100%" }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
                position={position}
                draggable={true}
                icon={customMarkerIcon}
                eventHandlers={{
                    dragend: handleMarkerMove,
                    mouseover: handleMouseOver,
                    mouseout: handleMouseOut,
                }}
            >
                 {showTooltip && (
                    <Tooltip permanent>
                        <div>Ubica el marcador donde necesitas </div>
                        <div>Fhanred. Haz click </div>
                        <div>guarda las coordenadas.</div>
                    </Tooltip>
                )}
                <Popup>
                    <div>
                        {position && (
                            <>
                                <p>Latitud: {position[0]}</p>
                                <p>Longitud: {position[1]}</p>
                                <button onClick={handleSave}>Guardar coordenadas</button>
                            </>
                        )}
                    </div>
                </Popup>
            </Marker>
        </MapContainer>
    );
}

export default MapView;




