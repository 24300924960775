import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { contractDetail } from "../../Redux/Actions/actions";
import { useParams, Link, useHistory } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import "./CustomersData.css";
import Logo from "../../assets/Logo/12.png";
import NavbarSignin from "../Navbar/NavbarSignin";


const ContractDetails = () => {
  const dispatch = useDispatch();
  const { n_contrato } = useParams();
  const contractDetails = useSelector((state) => state.contractDetails);
  const loading = useSelector((state) => state.loading);
  const error = useSelector((state) => state.error);
  const history = useHistory()
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const userRole = useSelector((state) => state.authentication.user ? state.authentication.user.id_role : null);

  useEffect(() => {
    dispatch(contractDetail(n_contrato));
  }, [dispatch, n_contrato]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!contractDetails || !contractDetails.Plan) {
    return null;
  }

  const {
   
    init_date,
    Plan,
    municipio,
    barrio_vereda,
    direccion,
    n_documento,
    estado_contrato,
    ultimo_pago,
    descuento,
    idStratus,
    caja_nap,
    retencion,
    marca_onu,
    mac,
    reporte_c_riesgo,
    estado_cp_correo,
    id_inventory,
    deuda_mes_actual,
    saldoAnterior,
  } = contractDetails;

  return (
    
    <Grid container className="container" marginTop={"5%"}>
    
    <Grid item sx={{ marginLeft: "auto" }}>
  <div className="small-buttons-container">
    {isAuthenticated && userRole !== 1 && (
      <Link to={`/cargaPromociones/${n_contrato}`}>
        <Button variant="contained" className="small-button" style={{ background: "#206482",marginRight:"10px", padding: "10px", color:"white", marginTop:"5%"}}>
          Aplicar Promoción
        </Button>
      </Link>
    )}
    <Button
      variant="contained"
      className="small-button"
      style={{ background: "#206482", padding: "10px", color:"white", marginTop:"5%"}}
      type="button"
      onClick={() => history.goBack()}
    >
      Volver
    </Button>
  </div>
</Grid>

      <NavbarSignin />
      
      <Grid className="user-table">
      <Grid
        style={{
          display: "flex",
          alignItems:"flex-start",
          
        }}
      >
       
        <img
          src={Logo}
          alt="Logo de la compañía"
          style={{ width: "20%", height: "50%",top:"30px",position:"initial" }}
        />
      </Grid>
      <h2 style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "#e0e0e0" }}>Detalles Contrato N° {n_contrato}</h2>
        <table className="table-container">
          <tbody>
            <tr>
              <td className="label">Fecha de inicio</td>
              <td>{init_date}</td>
            </tr>
            <tr>
              <td className="label">Plan</td>
              <td>{Plan.name_plan}</td>
            </tr>
            <tr>
              <td>Plan Costo</td>
              <td>{Plan.costo}</td>
            </tr>
            <tr>
              <td>Municipio</td>
              <td>{municipio}</td>
            </tr>
            <tr>
              <td>Barrio</td>
              <td>{barrio_vereda}</td>
            </tr>
            <tr>
              <td>Dirección</td>
              <td>{direccion}</td>
            </tr>
            <tr>
              <td>Documento</td>
              <td>{n_documento}</td>
            </tr>
            <tr>
              <td className="label">Estado Contrato</td>
              <td>
                {estado_contrato === "PENDIENTE FIRMA DIGITAL" ? (
                  <Link to={`/pdfContrato/${n_contrato}`}>
                    {estado_contrato}
                  </Link>
                ) : (
                  estado_contrato
                )}
              </td>
            </tr>
            <tr>
              <td>Ultimo Pago</td>
              <td>{ultimo_pago}</td>
            </tr>
            <tr>
              <td>Descuento</td>
              <td>{descuento}</td>
            </tr>
            <tr>
              <td>Deuda Mes Actual</td>
              <td>{deuda_mes_actual}</td>
            </tr>
            <tr>
              <td>Saldo Anterior</td>
              <td>{saldoAnterior}</td>
            </tr>
            <tr>
              <td>Estrato</td>
              <td>{idStratus}</td>
            </tr>
            <tr>
              <td>Caja NAP</td>
              <td>{caja_nap}</td>
            </tr>
            <tr>
              <td>Retencion</td>
              <td>{retencion}</td>
            </tr>
            <tr>
              <td>Marca Onu</td>
              <td>{marca_onu}</td>
            </tr>
            <tr>
              <td>Mac</td>
              <td>{mac}</td>
            </tr>
            <tr>
              <td>Reporte</td>
              <td>{reporte_c_riesgo}</td>
            </tr>
            <tr>
              <td>Estado cp Correo</td>
              <td>{estado_cp_correo}</td>
            </tr>
            <tr>
              <td>Id Inventory</td>
              <td>{id_inventory}</td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default ContractDetails;
