import React, { useRef }from 'react';
import { Box , IconButton} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';



import promo1 from '../images/Plan1.png';
import promo2 from '../images/Promo2.png';
import promo3 from '../images/Promo3.png';


const images = [promo1, promo2, promo3];

export default function ImageSlider() {
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
    return (
        <Box sx={{position: 'relative', width: '200%', height: '150%', backgroundColor:"#143e59" }}>
          <Swiper
            ref={swiperRef}
            spaceBetween={30}
            slidesPerView={1}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            loop={true}
          >
            {images.map((url, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="img"
                  src={url}
                  alt={`Slide ${index + 1}`}
                  sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <IconButton
        sx={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'white',
          zIndex: 10,
          borderRadius: '50%',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.8)', 
        }}
        onClick={handlePrev}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        sx={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'white',
          zIndex: 10,
          borderRadius: '50%',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.8)', 
        }}
        onClick={handleNext}
      >
        <NavigateNextIcon />
      </IconButton>
        </Box>
      );
    };

