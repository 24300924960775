import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { userInfo } from '../src/Redux/Actions/actions'
import SignIn from './Views/Admin/SignIn/SignIn';
import Home from './Views/Admin/Home/Home';
import Navbar from './components/Navbar/Navbar';
import MenuDesplegable from './components/MenuDesplegable/MenuDesplegable'
import Customers from './Views/Admin/Customers/Customers';
import CustomersData from './components/CustomersData/CustomersData';
import ForgotPassword from './components/Forgot/ForgotPassword';
import Register from './components/Register/Register';
import Billing from './Views/Admin/Billing/Billing';
import Perfil from './Views/Admin/Perfil/Perfil';
import Encashment from './Views/Admin/Encashment/Encashment';
import { links } from './data';
import ChangePassword from './Views/Admin/ChangePassword/ChangePassword';
import Contract from './components/Contract/Contract';
import ReceiptNumberForm from './Views/Admin/Encashment/ReceiptNumberForm';
import Summary from "./Views/Admin/Summary/Summary"
import MovementsDetail from './Views/Admin/MovementsDetails/MovementsDetail';
import ContractForm from './components/Contratos2/ContractForm';
import TicketManager from './Views/Admin/TicketManager/TicketManager';
import ImageAndSignatureCapture from './components/Contratos2/ImageAndSignatureCapture'
import ContractDetails from './components/CustomersData/ContractDetail';
import TaskUser from './Views/Admin/Perfil/TaskUser';
import TechnicalDetailsStep from './components/Contratos2/TechnicalDetailsStep'
import PDFGenerator from "./components/Contratos2/PDFGenerator"
import ContactClient from './components/CustomersData/ContactClient';
import CreateTicketForm from './Views/Admin/CreateTicketForm/CreateTicketForm';
import MapView from './components/MapView';
import AplicaFinanciacion from './components/Contratos2/AplicaFinanciacion';
import CargaPromociones from './components/CargaPromociones';
import Landing from './Views/Admin/Landing/Components/Landing.jsx';
import './App.css';
import TicketDetailUpdate from './Views/Admin/TicketDetails/TicketDetailUpdate.js';


function App() {
  const isAuthenticated = Boolean(userInfo && userInfo.name && userInfo.rol);

  return (
    <BrowserRouter>
      <div>
        <Route exact path="/">
          <Landing />
        </Route>
        <Navbar />
        <Route path="/ingresar">
          <SignIn />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route path="/signup">
          <Register />
        </Route>
        {isAuthenticated && <MenuDesplegable links={links} />}

        <Switch>
          <Route path="">
            <div style={{ flex: 3 }}>
              <Switch>
                <Route path="/perfil">
                  <Perfil />
                </Route>
                <Route path="/tareasAsignadas">
                  <TaskUser />
                </Route>
                <Route path="/clientes">
                  <Customers />
                </Route>
                <Route path="/contract/:n_contrato">
                  <ContractDetails />
                </Route>
                <Route path="/datosClientes">
                  <CustomersData />
                </Route>
                <Route
                  path="/clientesContacto/:n_documento"
                  component={ContactClient}
                />
                <Route path="/generarContrato">
                  <ContractForm />
                </Route>
                <Route path="/datosTecnicos/:n_contrato">
                  <TechnicalDetailsStep />
                </Route>
                <Route path="/financiacion/:n_contrato">
                  <AplicaFinanciacion />
                </Route>
                <Route
                  path="/cargaPromociones/:n_contrato"
                  component={CargaPromociones}
                />

                <Route path="/firma/:n_contrato">
                  <ImageAndSignatureCapture />
                </Route>
                <Route path="/facturacion">
                  <Billing />
                </Route>
                <Route path="/create-ticket">
                  <CreateTicketForm />
                </Route>
                <Route path="/ticket-detail-and-update/:id">
                  <TicketDetailUpdate />
                </Route>
                <Route path="/tickets-manager">
                  <TicketManager />
                </Route>
                <Route
                  path="/changePassword/:n_documento"
                  Component={ChangePassword}
                >
                  <ChangePassword />
                </Route>
                <Route path="/caja">
                  <Encashment />
                </Route>
                <Route path="/movements">
                  <MovementsDetail />
                </Route>
                <Route path="/resumen">
                  <Summary />
                </Route>
                <Route path="/nrecibo">
                  <ReceiptNumberForm />
                </Route>
                <Route path="/contracts">
                  <Contract />
                </Route>
                <Route path="/map">
                  <MapView />
                </Route>
                <Route path="/pdfContrato/:n_contrato">
                  <PDFGenerator />
                </Route>
              </Switch>
            </div>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
