import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Container,
  TextField,
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import "./TicketDetailUpdate.css";
import { createTicket, fetchTicketById } from "../../../Redux/Actions/actions";
import { useParams } from "react-router-dom";

function TicketDetailUpdate({ onSubmit = "", nextStep = "" }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const currentTicketDetail = useSelector(
    (state) => state.currentTicketDetail.data
  );
  const userInfo = useSelector((state) => state.authentication.user);
  console.log("datos del usario logueado", userInfo);
  console.log(typeof nextStep);

  const isValidPhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };

  useEffect(() => {
    dispatch(fetchTicketById(id));
    console.log(currentTicketDetail);
  }, []);

  return (
    <Container className="create-ticket-container" maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Detalle de ticket / Actualización
      </Typography>
      <hr></hr>
      <br></br>
      {currentTicketDetail && (
        <Formik
          initialValues={{
            n_contrato: currentTicketDetail?.n_contrato,
            ticket_type: currentTicketDetail?.ticket_type,
            reception_datetime: new Date(),
            served_by: currentTicketDetail.served_by,
            created_by: "aasdasd",
            observations: currentTicketDetail.observations,
            phone: currentTicketDetail.phone,
            status: currentTicketDetail.status,
            //AGREGAR CHECK de abrir ticket ahora?
            /* opening_datetime: new Date(),
            closing_datetime: new Date(), */
            closed_by: currentTicketDetail.closed_by,
            used_materials: currentTicketDetail.used_materials,
            collected_materials: currentTicketDetail.collected_materials,
            field_39: "1",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.n_contrato) {
              errors.n_contrato = "Campo requerido";
            }

            if (!values.ticket_type) {
              errors.ticket_type = "Campo requerido";
            }

            if (!values.served_by) {
              errors.served_by = "Campo requerido";
            }

            if (!values.observations) {
              errors.observations = "Campo requerido";
            }

            if (!isValidPhoneNumber(values.phone)) {
              errors.phone = "Número de teléfono inválido";
            }

            if (!values.status) {
              errors.status = "Campo requerido";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            console.log("Valores del formulario:", values);

            const createTicketResult = dispatch(createTicket(values));
            console.log({ createTicketResult });
            //nextStep();
            //setSubmitting(false);
          }}
        >
          {({ handleChange, values, errors }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Número de contrato"
                    name="n_contrato"
                    onChange={handleChange}
                    value={values.n_contrato}
                    fullWidth
                    error={!!errors.n_contrato}
                    helperText={errors.n_contrato}
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="ticket-type-select-label">
                      Tipo de ticket
                    </InputLabel>
                    <Select
                      labelId="ticket-type-select-label"
                      id="ticket-type-select"
                      name="ticket_type"
                      value={values.ticket_type}
                      onChange={handleChange}
                      error={!!errors.status}
                      helperText={errors.status}
                      readOnly
                    >
                      <MenuItem value={"INSTALACION CABLE E INTERNET"}>
                        Instalación cable e Internet
                      </MenuItem>
                      <MenuItem value={"MANTENIMIENTO"}>Mantenimiento</MenuItem>
                      <MenuItem value={"FALLA"}>Falla</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Fecha de Recepción"
                    name="reception_datetime"
                    value={values.reception_datetime}
                    onChange={handleChange}
                    fullWidth
                    readOnly
                    error={!!errors.reception_datetime}
                    helperText={errors.reception_datetime}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Atendido por:"
                    name="served_by"
                    onChange={handleChange}
                    value={values.served_by}
                    fullWidth
                    error={!!errors.served_by}
                    helperText={errors.served_by}
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Observaciones"
                    name="observations"
                    onChange={handleChange}
                    value={values.observations}
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.observations}
                    helperText={errors.observations}
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Teléfono"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone}
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-select-label">Estado</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      error={!!errors.status}
                      helperText={errors.status}
                      readOnly
                    >
                      <MenuItem value={"EN PROGRESO"}>En progreso</MenuItem>
                      <MenuItem value={"PENDIENTE"}>Pendiente</MenuItem>
                      <MenuItem value={"CERRADO"}>Cerrado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Cerrado por"
                    name="closed_by"
                    onChange={handleChange}
                    value={values.closed_by}
                    fullWidth
                    readOnly
                    error={!!errors.closed_by}
                    helperText={errors.closed_by}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                <TextField
                  label="Fecha de apertura"
                  name="opening_datetime"
                  onChange={handleChange}
                  value={values.opening_datetime}
                  fullWidth
                  error={!!errors.opening_datetime}
                  helperText={errors.opening_datetime}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Fecha de cierre"
                  name="closing_datetime"
                  onChange={handleChange}
                  value={values.closing_datetime}
                  fullWidth
                  error={!!errors.closing_datetime}
                  helperText={errors.closing_datetime}
                />
              </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    label="Materiales utilizados"
                    name="used_materials"
                    onChange={handleChange}
                    value={values.used_materials}
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.used_materials}
                    helperText={errors.used_materials}
                    readOnly
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Materiales recogidos"
                    name="collected_materials"
                    onChange={handleChange}
                    value={values.collected_materials}
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.collected_materials}
                    helperText={errors.collected_materials}
                    readOnly
                  />
                </Grid>
                {/* VALORES QUE SE LLENAN DESPUES DE QUE EL TICKET SE CREA, CUALES SERIAN?????
                - materiales
                - fecha de apertura/cierre
                - cerrado por
                
              
              */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Confirmar Datos
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
}

export default TicketDetailUpdate;
