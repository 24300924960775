export const SIGNIN_USER = 'SIGNIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USERS = "GET_USERS";
export const CLEAN_DETAIL = 'CLEAN_DETAIL';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_USER = "CREATE_USER";
export const INCREMENT_NUMBER_FACT = 'INCREMENT_NUMBER_FACT';
export const FETCH_USER_CONTRACTS_REQUEST = 'FETCH_USER_CONTRACTS_REQUEST';
export const FETCH_USER_CONTRACTS_SUCCESS = 'FETCH_USER_CONTRACTS_SUCCESS';
export const FETCH_USER_CONTRACTS_FAILURE = 'FETCH_USER_CONTRACTS_FAILURE';
export const FETCH_CONTRACT_DETAILS_REQUEST = 'FETCH_CONTRACT_DETAILS_REQUEST';
export const FETCH_CONTRACT_DETAILS_SUCCESS = 'FETCH_CONTRACT_DETAILS_SUCCESS';
export const FETCH_CONTRACT_DETAILS_FAILURE = 'FETCH_CONTRACT_DETAILS_FAILURE';
export const FETCH_LAST_RECEIPT_NUMBER = 'FETCH_LAST_RECEIPT_NUMBER';
export const FETCH_LAST_RECEIPT_NUMBER_SUCCESS = 'FETCH_LAST_RECEIPT_NUMBER_SUCCESS';
export const FETCH_LAST_RECEIPT_NUMBER_FAILURE = 'FETCH_LAST_RECEIPT_NUMBER_FAILURE';
export const UPDATE_RECEIPT_NUMBER = 'UPDATE_RECEIPT_NUMBER';
export const FETCH_SUMMARY_REQUEST = 'FETCH_SUMMARY_REQUEST';
export const FETCH_SUMMARY_SUCCESS = 'FETCH_SUMMARY_SUCCESS';
export const FETCH_SUMMARY_FAILURE = 'FETCH_SUMMARY_FAILURE';
export const CLEAN_CONTRACT_DETAILS = 'CLEAN_CONTRACT_DETAILS';
export const SEND_PAYMENT_REQUEST = 'SEND_PAYMENT_REQUEST';
export const SEND_PAYMENT_SUCCESS = 'SEND_PAYMENT_SUCCESS';
export const SEND_PAYMENT_FAILURE = 'SEND_PAYMENT_FAILURE';
export const CLEAN_USER_CONTRACTS = 'CLEAN_USER_CONTRACTS';
export const SHOW_NO_CONTRACTS_MODAL = 'SHOW_NO_CONTRACTS_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const ADD_RECEIPT = 'ADD_RECEIPT';
export const FETCH_MOVEMENTS_BY_CASHIER_REQUEST = 'FETCH_MOVEMENTS_BY_CASHIER_REQUEST';
export const FETCH_MOVEMENTS_BY_CASHIER_SUCCESS = 'FETCH_MOVEMENTS_BY_CASHIER_SUCCESS';
export const FETCH_MOVEMENTS_BY_CASHIER_FAILURE = 'FETCH_MOVEMENTS_BY_CASHIER_FAILURE';
export const FETCH_ALL_TICKETS_REQUEST = 'FETCH_ALL_TICKETS_REQUEST';
export const FETCH_ALL_TICKETS_SUCCESS = 'FETCH_ALL_TICKETS_SUCCESS';
export const FETCH_ALL_TICKETS_FAILURE = 'FETCH_ALL_TICKETS_FAILURE';
export const CARGAR_ARTICULOS_REQUEST  = 'CARGAR_ARTICULOS_REQUEST'
export const CARGAR_ARTICULOS_EXITO = 'CARGAR_ARTICULOS_EXITO'
export const CARGAR_ARTICULOS_ERROR = 'CARGAR_ARTICULOS_ERROR'
export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE';
export const CONTRACT_DETAIL_REQUEST = 'CONTRACT_DETAIL_REQUEST';
export const CONTRACT_DETAIL_SUCCESS = 'CONTRACT_DETAIL_SUCCESS';
export const CONTRACT_DETAIL_FAILURE = 'CONTRACT_DETAIL_FAILURE';
export const FETCH_ASSIGNED_TASKS_REQUEST = 'FETCH_ASSIGNED_TASKS_REQUEST';
export const FETCH_ASSIGNED_TASKS_SUCCESS = 'FETCH_ASSIGNED_TASKS_SUCCESS';
export const FETCH_ASSIGNED_TASKS_FAILURE = 'FETCH_ASSIGNED_TASKS_FAILURE';
export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST'
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS'
export const UPDATE_CONTRACT_FAILURE = 'UPDATE_CONTRACT_FAILURE'
export const UPDATE_CONTRACT_AND_IMAGES_REQUEST = 'UPDATE_CONTRACT_AND_IMAGES_REQUEST'
export const UPDATE_CONTRACT_AND_IMAGES_SUCCESS = 'UPDATE_CONTRACT_AND_IMAGES_SUCCESS'
export const UPDATE_CONTRACT_AND_IMAGES_ERROR = 'UPDATE_CONTRACT_AND_IMAGES_ERROR'
export const CHANGE_PASSWORD_REQUEST ='CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'CREATE_TICKET_FAILURE';
export const FETCH_TICKET_BY_ID_REQUEST = 'FETCH_TICKET_BY_ID_REQUEST';
export const FETCH_TICKET_BY_ID_SUCCESS = 'FETCH_TICKET_BY_ID_SUCCESS';
export const FETCH_TICKET_BY_ID_FAILURE = 'FETCH_TICKET_BY_ID_FAILURE';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_CUOTAS_REQUEST = 'GET_CUOTAS_REQUEST';
export const GET_CUOTAS_SUCCESS = 'GET_CUOTAS_SUCCESS';
export const GET_CUOTAS_FAILURE = 'GET_CUOTAS_FAILURE';

export const FETCH_FINANCIACIONES_REQUEST = 'FETCH_FINANCIACIONES_REQUEST';
export const FETCH_FINANCIACIONES_SUCCESS = 'FETCH_FINANCIACIONES_SUCCESS';
export const FETCH_FINANCIACIONES_FAILURE = 'FETCH_FINANCIACIONES_FAILURE';

export const FETCH_BILLS_REQUEST = 'FETCH_BILLS_REQUEST';
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_FAILURE = 'FETCH_BILLS_FAILURE';

export const GET_PROMOS_REQUEST = 'GET_PROMOS_REQUEST';
export const GET_PROMOS_SUCCESS = 'GET_PROMOS_SUCCESS';
export const GET_PROMOS_FAILURE = 'GET_PROMOS_FAILURE';
