import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssignedTasks } from '../../../Redux/Actions/actions';
import {NavLink} from 'react-router-dom';
import {Button, Link} from '@mui/material'

export default function TaskUser() {
    const dispatch = useDispatch();
    const userNDocumento = useSelector((state) => state.authentication.user.n_documento);
    const tasks = useSelector((state) => state.assign.data?.assignments || []);
  
    const tasksList = {
        2: 'Técnico',
        3: 'Asesor Comercial',
        4: 'Administrador',
        5: 'Contador',
      };

    useEffect(() => { 
        dispatch(fetchAssignedTasks(userNDocumento));
    }, [dispatch, userNDocumento]);

    const renderDescription = (description) => {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        return description.split(urlPattern).map((part, index) =>
            urlPattern.test(part) ? (
                <Link key={index} href={part} target="_blank" rel="noopener noreferrer">
                    {part}
                </Link>
            ) : (
                part
            )
        );
    };

    return (
        
        <div style={{marginTop:"10%"}} >
           
            <NavLink to="/perfil" >
              <Button style={{position: "absolute", right: "15px", top: "100px",background: "#206482", color:"white"}}>VOLVER</Button>{" "}
            </NavLink>
          
           <div className='container'>
            <h2 >Mis Tareas</h2>
            <div className="user-table">
                <table>
                    <thead>
                        <tr>
                                <th>Fecha</th>
                                <th>Horario Inicial</th>
                                <th>Horario Final</th>
                                <th>Tarea asignada</th>
                                <th>Descripción</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map(task => (
                            <tr key={task.id}>
                                <td>{task.taskDate}</td>
                                <td>{task.startTurno}</td>
                                <td>{task.endTurno}</td>
                                <td>{tasksList[task.taskId]}</td>
                                <td>{renderDescription(task.taskDescription)}</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    );
}
