import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../Config";
import NavbarSignin from "../Navbar/NavbarSignin";
import {
  Button,
  Container,
  TextField,
  Grid,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
import { cargarArticulos } from "../../Redux/Actions/actions";

function AplicaFinanciacion() {
  const { n_contrato } = useParams();
  const history = useHistory();
  const location = useLocation();
  const formattedDate = format(new Date(), "yyyy-MM-dd");

  const [formData, setFormData] = useState({
    fechaInicio: formattedDate,
    descripcionFinanciacion: "Cuota Cable",
    articulo: "",
    importe_articulo: 0,
    cantidad: "",
    precio_total: 0,
    cantidad_cuotas: 1, 
    cuotas_pagas: 0,
  });

  const articulos = useSelector((state) => state.articulos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarArticulos());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "articulo") {
      const selectedArticulo = articulos.find(
        (articulo) => articulo.name_articulo === value
      );
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        importe_articulo: selectedArticulo ? selectedArticulo.costo : 0,
      }));
    } else if (name === "cantidad") {
      const precioTotal = parseInt(value) * formData.importe_articulo;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        precio_total: precioTotal,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const contractData = location.state.formData;
      // Primero, envía los datos del contrato
      await axios.put(
        `${BASE_URL}/contract/modificar/${n_contrato}`,
        contractData
      );

      // Luego, envía los datos de la financiación
      const response = await axios.put(
        `${BASE_URL}/contract/${n_contrato}/financiacion`,
        { ...formData, n_contrato }
      );
      console.log("Respuesta de la solicitud:", response.data);
      alert("Contrato actualizado correctamente");
      history.push("/");
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      alert("Error al actualizar el contrato");
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <NavbarSignin />
      <Container maxWidth="sm">
        <Typography variant="h5" gutterBottom>
          Financiacion
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="fechaInicio"
                name="fechaInicio"
                value={formData.fechaInicio}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripcion"
                name="descripcionFinanciacion"
                value={formData.descripcionFinanciacion}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                label="Artículo"
                name="articulo"
                value={formData.articulo}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Selecciona un Artículo</em>
                </MenuItem>
                {articulos
                  .filter(articulo => !articulo.name_articulo.startsWith("PLAN"))
                  .map((articulo) => (
                    <MenuItem
                      key={articulo.name_articulo}
                      value={articulo.name_articulo}
                    >
                      {articulo.name_articulo}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cantidad"
                name="cantidad"
                value={formData.cantidad}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Precio Total"
                name="precio_total"
                value={formData.precio_total}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                label="Cantidad de Cuotas"
                name="cantidad_cuotas"
                value={formData.cantidad_cuotas}
                onChange={handleChange}
              >
                {[1, 2, 3, 4, 5, 6].map((cuotas) => (
                  <MenuItem key={cuotas} value={cuotas}>
                    {cuotas}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default AplicaFinanciacion;





  