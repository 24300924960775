import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {BASE_URL, URL_FRONT} from '../../../../Config.js'




import promo1 from '../images/Promo2.png';
import promo2 from '../images/Promo1.png';
import promo3 from '../images/Promo3.png';

const useStyles = makeStyles((theme) => ({
  workSectionWrapper: {
    
  },
  workSectionTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
    marginBottom:'0',
        
        
         
      }, 
  },
  primaryHeading: {
    marginTop: '80px',
    marginBottom:'30px',
    maxWidth: '700px',
    color: '#dce7ea',
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
  primaryText: {
    marginBottom:'30px',
    textAlign: 'center',
    maxWidth: '600px',
    color: '#b0bec5',
    fontSize: '1.5rem',
  },
  primaryButton: {
    marginBottom:'30px',
    padding: '0.9rem 1.75rem',
    backgroundColor: '#dce7ea',
    border: 'none',
    borderRadius: '5rem',
    fontSize: '1.1rem',
    cursor: 'pointer',
    fontWeight: '600',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: 'rgb(234, 234, 234)',
    },
  },
  card: {
    marginBottom: '40px',
    height: '360px',
    width:"640px",
    color: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      height: 'auto',
      backgroundSize: 'cover',
      
    },
  },
  title: {
    backgroundColor: 'rgba(255, 235, 59, 0.7)',
    textAlign: 'center',
    padding: '10px',
    marginBottom: '10px',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  text: {
    backgroundColor: 'rgba(33, 150, 243, 0.7)',
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    padding: '10px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  carouselContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselItemPadding: {
    padding: '40px',
    marginLeft: '10px',
  },
  infoOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    color: '#fff',
    textAlign: 'center',
  },
  workSectionBottom: {
    marginTop: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export default function Work() {
  const classes = useStyles();
  const [workInfoData, setWorkInfoData] = useState([]);

  const handleButtonClick = () => {
    window.open(`${URL_FRONT}/signup`, '_blank'); 
};

  useEffect(() => {
    axios.get(`${BASE_URL}/promos/listar`)
      .then(response => {
        if (!response.data.error) {
         
          const promosWithImages = response.data.data.map((promo, index) => {
            const imageMap = [promo1, promo2, promo3]; 
            return { ...promo, imagen_url: imageMap[index % imageMap.length] };
          });
          setWorkInfoData(promosWithImages);
        }
      })
      .catch(error => {
        console.error("Error fetching promotions", error);
      });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className={classes.workSectionWrapper}>
      <div className={classes.workSectionTop}>
        <h1 className={classes.primaryHeading}>Nuestros Servicios</h1>
        <p className={classes.primaryText}>
          Registrate e ingresá para conocer todos nuestros planes.
        </p>
        <button  onClick={handleButtonClick} className={classes.primaryButton}>
          Contratar Plan
        </button>
      </div>
      <div style={{ marginBottom: "10px", marginTop: "10px" }}>
        <Carousel
          className={classes.carouselContainer}
          responsive={responsive}
          ssr={true}
          infinite={true}
          keyBoardControl={true}
          containerClass="carousel-container"
          itemClass={classes.carouselItemPadding}
        >
          {workInfoData.map((data) => (
            <div key={data.promo_id}>
              <Card className={classes.card} style={{ backgroundImage: `url(${data.imagen_url})` }}>
                <div className={classes.image}></div>
                
                <Typography style={{color:"white"}} variant="h5" className={classes.text}>
                  {data.descripcion}
                </Typography>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
