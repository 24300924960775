import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import "./Register.css";
import { createUser } from "../../Redux/Actions/actions";
import NavbarSignin from "../Navbar/NavbarSignin";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Swal from "sweetalert2";

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submissionResult, setSubmissionResult] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    let errorTimeout;

    // Si el submissionResult es 'error', establece un temporizador para limpiar el estado después de 2 segundos
    if (submissionResult === "error") {
      errorTimeout = setTimeout(() => {
        setSubmissionResult(null); // Limpiar el estado después de 2 segundos
      }, 2000);
    }

    // Limpia el temporizador si el componente se desmonta o si submissionResult cambia antes de que se complete el temporizador
    return () => {
      clearTimeout(errorTimeout);
    };
  }, [submissionResult]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <NavbarSignin />

      <Formik
        initialValues={{
          tipo_persona: "none",
          razonSocial: "",
          apellidos: "",
          nombres: "",
          tipo_documento: "",
          n_documento: "",
          fecha_cumple: "",
          sexo: "none",
          email: "",
          password: "",
        }}
        validate={(values) => {
          let errors = {};
          // Validación de apellidos
          if (values.tipo_persona === "PERSONA_NATURAL" && !values.apellidos) {
            errors.apellidos =
              "Este campo es obligatorio. Por favor ingrese sus apellidos";
          } else {
            const nameRegex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
            if (!nameRegex.test(values.apellidos)) {
              errors.apellidos =
                "Este campo solo puede contener letras y espacios";
            }
          }

          // Validación de nombres
          if (values.tipo_persona === "PERSONA_NATURAL" && !values.nombres) {
            errors.nombres =
              "Este campo es obligatorio. Por favor ingrese sus nombres";
          } else {
            const nameRegex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
            if (!nameRegex.test(values.nombres)) {
              errors.nombres =
                "Este campo solo puede contener letras y espacios";
            }
          }

          // Validación de email
          if (!values.email) {
            errors.email =
              "Este campo es obligatorio. Por favor ingrese un correo electronico";
          } else if (
            !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
              values.email
            )
          ) {
            errors.email = "El correo no es valido";
          }

          // validacion de password
          if (!values.password) {
            errors.password =
              "Este campo es obligatorio. por favor ingrese una contraseña valida.";
          } else if (
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
              values.password
            )
          ) {
            errors.password =
              "La contraseña debe tener al menos 8 caracteres, al menos una letra minúscula, al menos una letra mayúscula, al menos un número y al menos un carácter especial.";
          }

          // validacion fecha cumpleaños
          if (!selectedDate) {
            errors.fecha_cumple =
              "Este campo es obligatorio. Por favor, seleccione una fecha.";
          } else {
            const formattedDate = selectedDate.toISOString().slice(0, 10);
            const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
            if (!datePattern.test(formattedDate)) {
              errors.fecha_cumple =
                "Fecha no válida. El formato debe ser YYYY-MM-DD.";
            } else {
              const [year, month, day] = formattedDate.split("-").map(Number);
              if (
                day <= 0 ||
                day > 31 || // Día válido entre 1 y 31
                month <= 0 ||
                month > 12 // Mes válido entre 1 y 12
              ) {
                errors.fecha_cumple = "Fecha no válida. Verifique día y mes.";
              }
            }
          }

          //validacion razonSocial
          if (
            values.tipo_persona === "PERSONA_JURIDICA" &&
            !values.razonSocial
          ) {
            errors.razonSocial =
              "Este campo es obligatorio. por favor ingrese el nombre de la razón social";
          }

          // validacion tipo de documento
          if (values.tipo_documento === "none") {
            errors.tipo_documento =
              "Este campo es obligatorio. Por favor seleccione una opción";
          }

          // validacion numero de documento
          if (!values.n_documento || values.n_documento.trim() === "") {
            errors.n_documento =
              "Este campo es obligatorio. Por favor ingrese el número de identificación";
          } else if (!/^[a-zA-Z0-9]+$/.test(values.n_documento)) {
            errors.n_documento =
              "El numero de documento no debe contener caracteres especiales ni espacios";
          }

          // validacion tipo de persona
          if (values.tipo_persona === "none") {
            errors.tipo_persona =
              "Este campo es obligatorio. Por favor seleccione una opción";
          }

          // validacion genero
          if (values.sexo === "none") {
            errors.sexo =
              "Este campo es obligatorio. Por favor seleccione una opción";
          }
          return errors;
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          try {
            if (!selectedDate) {
              throw new Error("Por favor seleccione una fecha de cumpleaños");
            }
            values.fecha_cumple = selectedDate.toISOString().split("T")[0];
            const response = await dispatch(createUser(values));

            console.log(response);
            if (response.success) {
              setSubmissionResult("success");
              resetForm();
              setTimeout(() => {
                setSubmissionResult(null);
                history.push("/ingresar");
              }, 2000);
            } else {
              setSubmissionResult("error");
              console.log("entre al else", response.errorMessage)
              if (
                response.errorMessage.includes("llave duplicada")
               
              ) {
                Swal.fire("Ya hay un usuario con este mail", "Si Usted es el propietario de este mail, puede recuperar su contraseña", "error");
              }
            }
          } catch (error) {
            console.error("Error al enviar el formulario:", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, values, isSubmitting }) => (
          <Container
            maxWidth="sm"
            style={{
              marginTop: "100px",
              backgroundColor: "rgba(32,94,125,0.10)",
              padding: "20px",
            }}
          >
            <Form>
              <Typography
                variant="h4"
                style={{
                  marginBottom: "20px",
                  color: "#fff",
                  textShadow: "2px 2px 4px rgba(32,94,125,0.9)",
                }}
              >
                Registro
              </Typography>

              <Grid container spacing={2} direction="column">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="tipo-persona-label">
                      Tipo de persona
                    </InputLabel>
                    <Field
                      as={Select}
                      id="tipo_persona"
                      name="tipo_persona"
                      labelId="Tipo persona"
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value={"none"}>Selecciona una opción</MenuItem>
                      <MenuItem value={"PERSONA_JURIDICA"}>Jurídica</MenuItem>
                      <MenuItem value={"PERSONA_NATURAL"}>Natural</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>

                <ErrorMessage
                  name="tipo_persona"
                  component="div"
                  className="error-message-reg"
                />

                {values.tipo_persona === "PERSONA_NATURAL" && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      type="text"
                      id="apellidos"
                      name="apellidos"
                      label="Apellido"
                      placeholder=""
                      className="labelInput"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <ErrorMessage
                  name="apellidos"
                  component="div"
                  className="error-message-reg"
                />

                {values.tipo_persona === "PERSONA_NATURAL" && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      type="text"
                      id="nombres"
                      name="nombres"
                      label="Nombres"
                      placeholder=""
                      className="labelInput"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <ErrorMessage
                  name="nombres"
                  component="div"
                  className="error-message-reg"
                />

                {values.tipo_persona === "PERSONA_JURIDICA" && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      type="text"
                      id="razonSocial"
                      name="razonSocial"
                      label="Nombre"
                      placeholder=""
                      className="labelInput"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                )}
                <ErrorMessage
                  name="razonSocial"
                  component="div"
                  className="error-message-reg"
                />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="tipo-documento-label">
                      Tipo de documento
                    </InputLabel>
                    <Field
                      as={Select}
                      id="tipo_documento"
                      name="tipo_documento"
                      labelId="tipo-documento-label"
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value={"none"}>Selecciona una opción</MenuItem>
                      <MenuItem value={"CC"}>CC</MenuItem>
                      <MenuItem value={"CE"}>CE</MenuItem>
                      <MenuItem value={"NIT"}>NIT</MenuItem>
                      <MenuItem value={"PP"}>PP</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>

                <ErrorMessage
                  name="tipo_documento"
                  component="div"
                  className="error-message-reg"
                />

                {values.tipo_persona === "PERSONA_JURIDICA" ||
                values.tipo_persona === "PERSONA_NATURAL" ? (
                  <Grid item xs={12} sm={6}>
                    {values.tipo_documento === "NIT" && (
                      <p className="verification-note">
                        NO debe registrar el DV, no debe enviar caracteres
                        especiales como espacios o letras
                      </p>
                    )}
                    {values.tipo_documento !== "NIT" &&
                      values.tipo_documento !== "" && (
                        <p className="verification-note">
                          NO debe enviar caracteres especiales como espacios o
                          letras
                        </p>
                      )}
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        type="text"
                        id="n_documento"
                        name="n_documento"
                        label="Numero de Documento"
                        className="labelInput"
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                ) : null}
                <ErrorMessage
                  name="n_documento"
                  component="div"
                  className="error-message-reg"
                />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="sexo-label">Sexo</InputLabel>
                    <Field
                      as={Select}
                      id="sexo"
                      name="sexo"
                      labelId="sexo-label"
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value={"none"}>Selecciona una opción</MenuItem>
                      <MenuItem value={"M"}>Masculino</MenuItem>
                      <MenuItem value={"F"}>Femenino</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>

                <ErrorMessage
                  name="sexo"
                  component="div"
                  className="error-message-reg"
                />

                <Grid item xs={12} sm={6}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Seleccione una fecha"
                  />
                </Grid>

                <ErrorMessage
                  name="fecha_cumple"
                  component="div"
                  className="error-message-reg"
                />

                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    type="text"
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    className="labelInput"
                    sx={{ width: "100%" }}
                  />
                </Grid>

                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message-reg"
                />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      label="Password"
                      className="passwordInput"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityIcon
                                className="passwordInput-eyeIcon"
                                onClick={togglePasswordVisibility}
                              />
                            ) : (
                              <VisibilityOffIcon
                                className="passwordInput-eyeIcon"
                                onClick={togglePasswordVisibility}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    sx={{ marginBottom: "1rem", background: "#206482" }}
                  >
                    <FaUser /> Crear cuenta
                  </Button>
                </Grid>

                <Grid container item xs={12} sm={6} alignItems="center">
                  <label>¿Ya tienes tu cuenta?</label>
                  <NavLink to="/" style={{ marginLeft: "0.5rem" }}>
                    <span>Iniciar sesión</span>
                  </NavLink>
                </Grid>
              </Grid>
            </Form>
          </Container>
        )}
      </Formik>
    </>
  );
}

export default Register;
