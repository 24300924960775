import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  contractDetail,
  getPromos,
  fetchLastReceiptNumber,
} from "../Redux/Actions/actions";
import { BASE_URL } from "../Config";
import ReceiptPDF from "./ReceiptPDF";
import DatePicker from "react-datepicker";
import { format, addMonths, subDays, endOfMonth } from "date-fns";

const CargaPromociones = () => {
  const history = useHistory();
  const { n_contrato } = useParams();
  const dispatch = useDispatch();
  const contractDetails = useSelector((state) => state.contractDetails);
  const promos = useSelector((state) => state.promos.data) || [];
  const lastReceiptNumber = useSelector((state) => state.lastReceiptNumber);
  const usersData = useSelector((state) => state.usersData);

  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
  });

  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    const promoDuration = 12;
    return endOfMonth(addMonths(new Date(now.getFullYear(), now.getMonth() + 1, 1), promoDuration - 1));
  });

  const [selectedPromo, setSelectedPromo] = useState(null);
  const [formData, setFormData] = useState({
    n_contrato: "",
    months: [],
  });
  const [totalCost, setTotalCost] = useState(0);
  const [userName, setUserName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Davivienda");
  const [showPDF, setShowPDF] = useState(false);
  const [receiptData, setReceiptData] = useState(null);

  const formatDate = (date) => {
    return format(date, "yyyy-MM-dd"); 
  };

  useEffect(() => {
    dispatch(contractDetail(n_contrato));
    dispatch(getPromos());
    dispatch(fetchLastReceiptNumber());
  }, [dispatch, n_contrato]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      n_contrato: n_contrato,
    }));
  }, [n_contrato]);

  useEffect(() => {
    console.log("Promociones en el componente:", promos);
  }, [promos]);

  useEffect(() => {
    if (contractDetails && contractDetails.n_documento) {
      const user = usersData.find(
        (user) => user.n_documento === contractDetails.n_documento
      );
      if (user) {
        setUserName(user.name_razonSocial);
      }
    }
  }, [usersData, contractDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    if (selectedPromo && lastReceiptNumber !== undefined) {
      const newReceiptData = {
        receipt: lastReceiptNumber + 1,
        paymentDate: new Date().toISOString().split("T")[0],
        paymentTime: new Date().toLocaleTimeString(),
        contract: n_contrato,
        n_documento: contractDetails?.n_documento || "",
        municipio: contractDetails?.municipio || "",
        direccion: contractDetails?.direccion || "",
        importe:
          (selectedPromo.mesesPagos - selectedPromo.mesesDescontados) *
          (contractDetails.Plan?.costo || 0),
        description: selectedPromo?.descripcion || "",
        paymentMethod: paymentMethod,
        cashierName: "MercedesAdmin",
        username: userName,
      };
      console.log("Promoción seleccionada:", selectedPromo);
      

      try {
        const response = await axios.post(`${BASE_URL}/caja`, newReceiptData);
        console.log("Recibo enviado correctamente al backend");

        setReceiptData(newReceiptData);
        setShowPDF(true); 

        try {
          await axios.put(`${BASE_URL}/contract/modificar/${n_contrato}`, {
            estado_contrato: "EN PROMOCION",
          });

          try {
            await axios.post(`${BASE_URL}/promos/aplicar/${n_contrato}`, {
              promo_id: selectedPromo.promo_id,
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              isPromoActive: true,
              factura: false,
              totalCost, 
            });
            
          } catch (error) {
            console.error(
              "Error al enviar el objeto de promoción al backend:",
              error
            );
          }
          console.log("Estado del contrato actualizado a 'EN PROMOCION'");
        } catch (error) {
          console.error("Error al actualizar el estado del contrato:", error);
        }
      } catch (error) {
        console.error("Error al enviar el recibo al backend:", error);
      }
    }
  };

  const handlePromoChange = (promo) => {
    setSelectedPromo(promo);

    const mesesPagados = promo.mesesPagos - promo.mesesDescontados;
    setTotalCost(mesesPagados * (contractDetails.Plan?.costo || 0));

    const promoEndDate = endOfMonth(addMonths(startDate, promo.mesesPagos - 1));
    setEndDate(promoEndDate);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleDownloadReceipt = async () => {
    try {
      const pdfResponse = await axios.get(
        `${BASE_URL}/caja/recibo/${lastReceiptNumber}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([pdfResponse.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recibo_pago.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el recibo:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        marginTop: "5%",
      }}
    >
      <Grid container direction="column" spacing={1} alignItems="center">
        <Grid
          item
          style={{
            marginBottom: "10px",
            backgroundColor: "#e0e0e0",
            padding: "5px",
          }}
        >
          <h2>Pago Promoción - Contrato N° {n_contrato}</h2>
        </Grid>
        
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="Número de Contrato"
                  value={formData.n_contrato}
                  disabled
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="Nombre del Plan"
                  value={contractDetails.Plan?.name_plan || ""}
                  disabled
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="Costo del Plan"
                  value={contractDetails.Plan?.costo || ""}
                  disabled
                />
              </Grid>
              </Grid>
              <Grid container direction="column" spacing={1}>
              <Grid item>
                <h3 style={{marginTop:"5px"}}>Promociones Disponibles</h3>
                {promos.length > 0 ? (
                  promos.map((promo) => (
                    <FormControlLabel
                      key={promo.promo_id}
                      control={
                        <Checkbox
                          checked={selectedPromo?.promo_id === promo.promo_id}
                          onChange={() => handlePromoChange(promo)}
                        />
                      }
                      label={`Promo: ${promo.descripcion} - ${promo.mesesPagos} meses`}
                    />
                  ))
                ) : (
                  <p>No hay promociones disponibles</p>
                )}
              </Grid>
              <Grid item xs={12} sm={6} direction="row">
                <h3 style={{marginTop:"5px"}}>Elegir Fechas</h3>
                <DatePicker selected={startDate} onChange={setStartDate} />
                <DatePicker selected={endDate} onChange={setEndDate} style={{ marginLeft: 20 }} />
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel>Método de Pago</InputLabel>
                  <Select
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    label="Método de Pago"
                  >
                    <MenuItem value="Davivienda">Davivienda</MenuItem>
                    <MenuItem value="corresponsalBancario">Corresponsal Bancario</MenuItem>
                    <MenuItem value="efectivo">Efectivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <h3 style={{ marginBottom: "5px", marginTop: "5px" }}>Total Costo: ${totalCost}</h3>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    background: "#206482",
                    marginRight: "10px",
                    marginTop: "5px",
                    padding: "8px",
                    color:"white"
                  }}
                  type="button"
                  onClick={() => history.goBack()}
                >
                  Volver
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "#206482",
                    marginRight: "10px",
                    marginTop: "5px",
                    padding: "8px",
                    color:"white"
                  }}
                >
                  Cargar promoción
                </Button>
                {showPDF && receiptData && (
                  <PDFDownloadLink
                    document={<ReceiptPDF receiptData={receiptData} />}
                    fileName="recibo_pago.pdf"
                  >
                    {({ loading }) => (
                      <Button
                        variant="contained"
                        style={{
                          background: "#206482",
                          marginRight: "10px",
                          marginTop: "5px",
                          padding: "8px",
                          color:"white"
                        }}
                        onClick={handleDownloadReceipt}
                      >
                        Descargar Recibo
                      </Button>
                    )}
                  </PDFDownloadLink>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
        
      </Grid>
    </div>
  );
};

export default CargaPromociones;


