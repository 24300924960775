import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import style from "./SignIn.module.css";
import { BsLock } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { userInfo } from "../../../Redux/Actions/actions";
import { handleChange, login } from "./funcs";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NavbarSignin from "../../../components/Navbar/NavbarSignin";

function SignIn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const credentials = useSelector((state) => state.userInfo);
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    handleChange(e, setErrors, setInput, input);
  };
  function handleClick1() {
    history.push("/signup");
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (input.email && input.password) {
      try {
        await login(input, dispatch, credentials, userInfo);
        if (isAuthenticated) {
          history.push("/perfil");
        }
      } catch (error) {
        console.error("Se produjo un error:", error.message);
      }
    } else {
      // Mostrar Sweet Alert con el mensaje deseado
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor completa todos los campos antes de continuar.",
      });
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/ingresar");
    }
  }, [isAuthenticated, history]);

  return (
    <div className={style.container}>
      <NavbarSignin />

      <form className={style.formContainerSig} onSubmit={submitHandler}>
        <h2 className={style.formTitleSig}>Iniciar sesión</h2>
        <label>
          <input
            type="email"
            value={input.email}
            name="email"
            placeholder="Correo electrónico"
            onChange={(e) => handleInputChange(e)}
          />
          <p className={style.error}>{errors.email}</p>
        </label>
        <label>
          <div className={style.passwordInput}>
            <input
              type={showPassword ? "text" : "password"}
              value={input.password}
              name="password"
              placeholder="Contraseña"
              onChange={(e) => handleInputChange(e)}
            />
            {showPassword ? (
              <VisibilityIcon
                sx={{ fontSize: "medium", marginLeft: 1 }}
                onClick={togglePasswordVisibility}
              />
            ) : (
              <VisibilityOffIcon
                sx={{ fontSize: "medium", marginLeft: 1 }}
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          <p className={style.error}>{errors.password}</p>
        </label>
        <button className={`${style.button} ${style.red}`} type="submit">
          <BsLock className={style.icon} /> Ingresar
        </button>
        <button
          className={`${style.button} ${style.red}`}
          onClick={handleClick1}
        >
          <FaUser className={style.icon} /> Crear cuenta
        </button>
        <NavLink to="/forgotPassword" className={style.forgotPasswordLink}>
          ¿Has olvidado tu contraseña?
        </NavLink>
      </form>
    </div>
  );
}

export default SignIn;
