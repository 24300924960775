import React from 'react';
import Logo from "../images/LOGO2.png";
import { BsInstagram, BsEnvelope, BsWhatsapp } from "react-icons/bs";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },
  footerSectionOne: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1200px',
    padding: '20px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '10px 0',
    },
  },
  footerLogoContainer: {
    '& img': {
      height: '150px',
      [theme.breakpoints.down('sm')]: {
        display: 'none', // Ocultar logo en pantallas pequeñas
      },
    },
  },
  footerIcons: {
    display: 'flex',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  footerIcon: {
    color: '#dce7ea',
    fontSize: '1.5rem',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#ffeb3b',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  footerSectionColumns: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    padding: '10px 0',
    width: '100%',
    maxWidth: '1200px',
    borderTop: '1px solid #dce7ea',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '10px',
      padding: '5px 0',
    },
  },
  footerColumnItem: {
    color: '#dce7ea',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': {
      color: '#ffeb3b',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footerWrapper}>
      <div className={classes.footerSectionOne}>
        <div className={classes.footerLogoContainer}>
          <img src={Logo} alt="Logo" />
        </div>
        <div className={classes.footerIcons}>
          <a href="https://www.instagram.com/fhanred.sas/" target="_blank" rel="noopener noreferrer">
            <BsInstagram className={classes.footerIcon} />
          </a>
          <a href="mailto:fhanrednotificaciones@gmail.com" target="_blank" rel="noopener noreferrer">
            <BsEnvelope className={classes.footerIcon} />
          </a>
          <a href="https://wa.me/57313526460" target="_blank" rel="noopener noreferrer">
            <BsWhatsapp className={classes.footerIcon} />
          </a>
        </div>
      </div>
      <div className={classes.footerSectionColumns}>
        <span className={classes.footerColumnItem}>Terms & Conditions</span>
        <span className={classes.footerColumnItem}>Privacy Policy</span>
      </div>
    </div>
  );
}
