import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import marker2 from '../images/marker2.svg';
import "leaflet/dist/leaflet.css";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  mapWrapper: {
    width: '60%',
    height: '60%',
    position: 'relative',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.6)',
    [theme.breakpoints.down('sm')]: {
       
        alignItems: 'center',
        height: '80%',
        width: '80%', // Ensures the container takes the full width
        marginLeft:'12%',
       
      },
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const customMarkerIcon = L.icon({
  iconUrl: marker2,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});

const generateRandomPosition = (center, radius) => {
  const y0 = center.lat;
  const x0 = center.lng;
  const rd = radius / 111300; // about 111300 meters in one degree

  const u = Math.random();
  const v = Math.random();
  const w = rd * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  const xp = x / Math.cos(y0);

  return {
    lat: y + y0,
    lng: xp + x0,
  };
};

function LeafletMap() {
  const classes = useStyles();

  // Define the center and radius
  const center = { lat: 4.267645, lng: -73.524284 }; // Approximate center between Restrepo and Cumaral
  const radius = 5000; // 5 kilometers

  // Generate multiple random positions
  const generatePositions = (numPositions) => {
    const positions = [];
    for (let i = 0; i < numPositions; i++) {
      positions.push(generateRandomPosition(center, radius));
    }
    return positions;
  };

  const [positions, setPositions] = useState(generatePositions(300));

  const handleMarkerDrag = (e, index) => {
    const newPositions = [...positions];
    newPositions[index] = {
      ...newPositions[index],
      lat: e.target.getLatLng().lat,
      lng: e.target.getLatLng().lng,
    };
    setPositions(newPositions);
  };

  return (
    <div className={classes.mapWrapper}>
      <MapContainer
        center={center}
        zoom={13}
        className={classes.mapContainer}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {positions.map((position, index) => (
          <Marker
            key={index}
            position={[position.lat, position.lng]}
            draggable={true}
            icon={customMarkerIcon}
            onMove={(e) => handleMarkerDrag(e, index)}
          >
            <Popup>
              <div>
                <p>Latitud: {position.lat}</p>
                <p>Longitud: {position.lng}</p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

export default LeafletMap;
