import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaUndo,
} from 'react-icons/fa';
import {Button, ButtonGroup, TextField, Grid} from '@mui/material';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import './CustomersData.css';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
   
  buttonGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    "& > *": {
      marginRight: theme.spacing(2),
      color: "white",
      
    },
  },
  container: {
    marginTop: theme.spacing(2), 
    margin: theme.spacing(2), 
  },
}));

function CustomersData() {
   const classes = useStyles();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const userRole = useSelector((state) => state.authentication.user?.id_role);
  const history = useHistory();

  const clientesData = useSelector((state) => state.usersData);
  const [clientes, setClientes] = useState(clientesData);
  const [orderBy, setOrderBy] = useState('n_documento');
  const [orderAsc, setOrderAsc] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userNotFound, setUserNotFound] = useState(false);
  const [usersPerPage, setUsersPerPage] = useState(10);

  useEffect(() => {
    if (
      !isAuthenticated ||
      (userRole !== 2 && userRole !== 3 && userRole !== 4)
    ) {
      history.push("/");
    }
  }, [isAuthenticated, userRole, history]);

  useEffect(() => {
    const updateUsersPerPage = () => {
      const width = window.innerWidth;
      if (width <= 600) {
        setUsersPerPage(2); 
      } else {
        setUsersPerPage(10); 
      }
    };
    updateUsersPerPage();
    window.addEventListener('resize', updateUsersPerPage);
    return () => window.removeEventListener('resize', updateUsersPerPage);
  }, []);

  const handleSort = (column) => {
    let newOrderAsc = true;
    if (column === orderBy) {
      newOrderAsc = !orderAsc;
      setOrderAsc(newOrderAsc);
    } else {
      setOrderBy(column);
    }
    const sortedData = _.orderBy(
      filteredClientes.length > 0 ? filteredClientes : clientes,
      [column],
      [newOrderAsc ? 'asc' : 'desc']
    );
    setFilteredClientes(sortedData);
  };

  const handleSearch = () => {
    const filteredExactMatch = clientes.filter((cliente) => {
      // Verifica si hay alguna coincidencia exacta en n_contrato
      return cliente.Contracts && cliente.Contracts.find(contract => contract.n_contrato === parseInt(searchText));
    });
  
    const filtered = clientes.filter((cliente) => {
      // Si no hay coincidencia en n_contrato, se realiza la búsqueda en otros campos
      return (
        (cliente.name_razonSocial && cliente.name_razonSocial.toLowerCase().includes(searchText.toLowerCase())) ||
        (cliente.n_documento && cliente.n_documento.includes(searchText)) ||
        (cliente.Contracts && cliente.Contracts[0] &&
          ((cliente.Contracts[0].name_plan && cliente.Contracts[0].name_plan.toLowerCase().includes(searchText.toLowerCase())) ||
          (cliente.Contracts[0].estado_contrato && cliente.Contracts[0].estado_contrato.toLowerCase().includes(searchText.toLowerCase()))))
      );
    });
  
    const filteredResults = [...filteredExactMatch, ...filtered];
  
    setFilteredClientes(filteredResults);
    setCurrentPage(1);
    setUserNotFound(filteredResults.length === 0);
  };
  
  const handleResetSearch = () => {
    setClientes(clientesData);
    setSearchText('');
    setFilteredClientes([]);
    setCurrentPage(1);
    setUserNotFound(false);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  let renderClientes = [];

  if (filteredClientes && filteredClientes.length > 0) {
      renderClientes = filteredClientes.slice(indexOfFirstUser, indexOfLastUser);
  } else if (clientes && clientes.length > 0) {
      renderClientes = clientes.slice(indexOfFirstUser, indexOfLastUser);
  }
 

  const totalUsersCount = 
    (filteredClientes && filteredClientes.length > 0)
        ? filteredClientes.length
        : (clientes && clientes.length > 0)
            ? clientes.length
            : 0; // Si ambas son `null` o `undefined`, devuelve 0

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalUsersCount / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Grid style={{marginLeft:"20px", marginRight:"20px"}} >

<Grid container  style={{marginTop: '6%', marginBottom: '10px' ,justifyContent:"center" ,alignItems:"center"}} >
  <Grid item className={classes.buttonGroup}>
    <TextField
      type="text"
      placeholder="Buscar por usuario, cédula, estado contrato"
      value={searchText}
      className="search-input"
      onChange={(e) => setSearchText(e.target.value)}
    />
  
  
    <ButtonGroup >
      <Button variant="contained" style={{ background: "#206482", padding: "12px" }} onClick={handleSearch}>Buscar</Button>
      <Button variant="contained" style={{ background: "#206482", padding: "12px" }} onClick={handleResetSearch}><FaUndo/></Button> 
      <Link to="/clientes" style={{textDecoration: 'none', color:"white"}}> 
        <Button variant="contained" style={{ background: "#206482", padding: "12px" }}>Volver</Button>
      </Link>
    </ButtonGroup>
  
  </Grid>
</Grid>

      
      {userNotFound && (
        <p className="error">Usuario no registrado, verifique nuevamente</p>
      )}
      <Grid >
        <table className="user-table">
          <thead>
            <tr className='th-table-title'>
              <th onClick={() => handleSort('n_documento')} className={orderBy === 'n_documento' ? (orderAsc ? 'asc' : 'desc') : ''}>
                Cédula
                {orderBy === 'n_documento' && (
                  <span>{orderAsc ? (<span className="asc"><FaArrowAltCircleUp className="asc-icon" /> - Ascendente </span>) : (<span className="desc"><FaArrowAltCircleDown className="desc-icon" /> - Descendente </span>)}</span>
                )}
              </th>
              <th onClick={() => handleSort('name_razonSocial')} className={orderBy === 'name_razonSocial' ? (orderAsc ? 'asc' : 'desc') : ''}>
                Nombre/Apellido<br></br>Razón social
                {orderBy === 'name_razonSocial' && (
                  <span>{orderAsc ? (<span className="asc"><FaArrowAltCircleUp className="asc-icon" /> - Ascendente </span>) : (<span className="desc"><FaArrowAltCircleDown className="desc-icon" /> - Descendente </span>)}</span>
                )}
              </th>
              <th># Contrato</th>
              <th>Plan Contratado</th>
              <th>Último Pago</th>
              <th>Estado contrato</th>
            </tr>
          </thead>
          <tbody>
            {renderClientes.map((cliente) => (
              <tr key={cliente.n_documento}>
              <td>
                  {/* Utiliza Link para hacer que n_documento sea clicable y redirigir a la ruta /clientesContacto con el parámetro n_documento */}
                  <Link to={`/clientesContacto/${cliente.n_documento}`}>
                      {cliente.n_documento}
                  </Link></td>
                <td>{cliente.name_razonSocial}</td>
                <td>
                {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                     

                      <Link to={`/contract/${cliente.Contracts[0].n_contrato}`}>{cliente.Contracts[0].n_contrato}</Link>
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.n_contrato}-${index}`}>
                            <Link to={`/contract/${contract.n_contrato}`}>{contract.n_contrato}</Link>
                          </li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'Sin contrato'
                  )}
                </td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      cliente.Contracts[0].name_plan
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.name_plan}-${index}`}>{contract.name_plan}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      cliente.Contracts[0].ultimo_pago
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.ultimo_pago}-${index}`}>{contract.ultimo_pago}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>
                  {cliente.Contracts && cliente.Contracts.length > 0 ? (
                    cliente.Contracts.length === 1 ? (
                      cliente.Contracts[0].estado_contrato
                    ) : (
                      <ul>
                        {cliente.Contracts.map((contract, index) => (
                          <li key={`${contract.estado_contrato}-${index}`}>{contract.estado_contrato}</li>
                        ))}
                      </ul>
                    )
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-container">
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className={`paginate ${currentPage === 1 ? 'active' : ''}`}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <BsChevronLeft style={{ width: '20px', height: '20px' }} />
            </span>
          </button>
          {pageNumbers.slice(currentPage - 1, currentPage + 15).map((number) => (
            <button key={number} onClick={() => paginate(number)} className={`paginate ${currentPage === number ? 'active' : ''}`}>
              <span className="page-number">{number}</span>
            </button>
          ))}
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === pageNumbers.length} className={`paginate ${currentPage === pageNumbers.length ? 'active' : ''}`}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <BsChevronRight style={{ width: '20px', height: '20px' }} />
            </span>
          </button>
        </div>
      </Grid>
    </Grid>
  );
}


export default CustomersData;
