import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  contactSection: {
    minHeight: '40vh', 
    width: '100%',
    padding: '20px', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    background: 'transparent',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0', 
      marginRight: '0', 
      alignItems: 'center',
      textAlign: 'center',
      width: '95%', 
      padding: '0 5%',
    },
  },
  primaryHeading: {
    fontSize: 'clamp(3rem, 7vw, 4rem)', 
    color: '#dce7ea',
    maxWidth: '600px',
    textAlign: 'center', 
    zIndex: 0,
    background: 'transparent',
    marginLeft:'20%',
    
    [theme.breakpoints.down('sm')]: {
    maxWidth: '700px',
    color: '#dce7ea',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    margin:'10%',
    textShadow: '2px 2px 4px rgba(255, 255, 0, 0.9)',
      
      
       
    }, 
  },
  headingContainer: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: '100%',
      marginLeft:'10%',
    },
  }
}));

function Contact() {
  const classes = useStyles();

  return (
    <Grid id="contact-section" className={classes.contactSection}>
      <Grid className={classes.headingContainer} style={{ marginBottom: '30px' }}> 
        <h1 className={classes.primaryHeading}>
          Fhanred ya está en todos lados.<br></br>Contratanos vos también.
        </h1>
      </Grid>
    </Grid>
  );
}

export default Contact;

