import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Config";
import NavbarSignin from "../Navbar/NavbarSignin";
import {
  Button,
  Container,
  TextField,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

function TechnicalDetailsStep() {
  const { n_contrato } = useParams();
  const history = useHistory();
  const [isFinanciacionChecked, setIsFinanciacionChecked] = useState(false);
  const [formData, setFormData] = useState({
    caja_nap: "",
    reporte_c_riesgo: false,
    mac: "",
    marca_onu: "",
    vendedor: "",
    estado_contrato: "",
    aplicar_financiacion: false,
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "reporte_c_riesgo" ? checked : value,
    }));

    if (name === "aplicar_financiacion") {
      setIsFinanciacionChecked(checked);
    }
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    if (name === "aplicar_financiacion") {
      setIsFinanciacionChecked(checked);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Datos del contrato que se van a enviar al backend:", formData);
      const response = await axios.put(
        `${BASE_URL}/contract/modificar/${n_contrato}`,
        formData
      );
      console.log("Respuesta de la solicitud:", response.data);
      alert("Contrato actualizado correctamente");
      history.push("/");
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      alert("Error al actualizar el contrato");
    }
  };

  const handleFinanciacionClick = (e) => {
    e.stopPropagation();
    history.push({
      pathname: `/financiacion/${n_contrato}`,
      state: { formData: formData },
    });
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <NavbarSignin />
      <Container maxWidth="sm">
        <Typography variant="h5" gutterBottom>
          Datos Técnicos
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Caja Nap"
                name="caja_nap"
                value={formData.caja_nap}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                label="Reporte Con Riesgo"
                name="reporte_c_riesgo"
                value={formData.reporte_c_riesgo ? "SI" : "NO"}
                onChange={handleChange}
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="MAC"
                name="mac"
                value={formData.mac}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Marca ONU"
                name="marca_onu"
                value={formData.marca_onu}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Vendedor"
                name="vendedor"
                value={formData.vendedor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                label="Estado del Contrato"
                name="estado_contrato"
                value={formData.estado_contrato}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Selecciona un estado</em>
                </MenuItem>
                <MenuItem value="PENDIENTE INSTALACION">
                  SOLICITUD RECHAZADA
                </MenuItem>
                <MenuItem value="PENDIENTE FIRMA DIGITAL">
                  PENDIENTE FIRMA DIGITAL
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isFinanciacionChecked}
              >
                Enviar
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.aplicar_financiacion}
                    onChange={handleCheckboxChange}
                    name="aplicar_financiacion"
                    color="primary"
                  />
                }
                label="Aplicar Financiación"
                style={{ marginLeft: "50px" }}
              />
              {formData.aplicar_financiacion && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinanciacionClick}
                  style={{ marginLeft: "10px" }}
                >
                  Cargar Financiación
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default TechnicalDetailsStep;






