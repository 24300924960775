import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import * as Yup from "yup";
import { format } from "date-fns";
import { BASE_URL } from "../../../Config";
import Logo from "../../../assets/Logo/12.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import {
  fetchLastReceiptNumber,
  addReceipt,
} from "../../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "& > *": {
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& > *": {
        marginRight: 0,
      },
    },
  },
  formGroup: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  textField: {
    width: "25%",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: "inline-flex",
  },
}));

function Encashment() {
  const classes = useStyles();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const userRole = useSelector((state) => state.authentication.user?.id_role);
  const username = useSelector(
    (state) => state.authentication.user?.razon_social
  );

  console.log("Valor de username:", username); // Agregar este console.log
  const dispatch = useDispatch();
  const lastReceiptNumber = useSelector((state) => state.lastReceiptNumber);
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPDF, setShowPDF] = useState(false);
  const [userBills, setUserBills] = useState([]);
  const [name_razonSocial, setNameRazonSocial] = useState("");
  const [documento, setDocumento] = useState("");
  const [response, setResponse] = useState("");
  const [importe, setImporte] = useState(0);
  const [selectedBills, setSelectedBills] = useState([]);
  const [description, setDescription] = useState("");

  //  const today = new Date();
  const formattedDate = format(new Date(), "yyyy-MM-dd");
  const formattedTime = format(new Date(), "HH:mm");

  const initialValues = {
    receipt: lastReceiptNumber !== null ? (parseInt(lastReceiptNumber) + 1).toString() : "1",
    paymentDate: formattedDate,
    paymentTime: formattedTime,
    n_contrato: "",
    n_documento: "",
    username: "",
    municipio: "",
    direccion: "",
    importe: 0,
    description: "",
    paymentMethod: "",
    cashierName: userRole === 2 || userRole === 3 ? username : "",
  };

  const validationSchema = Yup.object().shape({
    paymentDate: Yup.date().required("Este campo es obligatorio"),
    paymentTime: Yup.string().required("Este campo es obligatorio"),
    username: Yup.string().required("Este campo es obligatorio"),
    municipio: Yup.string().required("Este campo es obligatorio"),
    direccion: Yup.string().required("Este campo es obligatorio"),
    n_contrato: Yup.string().required("Este campo es obligatorio"),
    importe: Yup.number()
      .required("Importe es requerido")
      .min(0, "Importe debe ser mayor o igual a 0"),
    //description: Yup.string().required("Este campo es obligatorio"),
    paymentMethod: Yup.string().required("Selecciona una Opción"),
  });

  useEffect(() => {
    if (
      !isAuthenticated ||
      (userRole !== 2 && userRole !== 3 && userRole !== 4)
    ) {
      history.push("/perfil");
    }
  }, [isAuthenticated, userRole, history]);

  useEffect(() => {
    dispatch(fetchLastReceiptNumber());
  }, [dispatch]);

  const fetchBills = async (n_contrato) => {
    try {
      const response = await axios.get(`${BASE_URL}/caja/${n_contrato}/bills`);
      console.log("Respuesta del servidor:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error al obtener las facturas del usuario:", error);
      throw error;
    }
  };

  const handleContractChange = async (e, handleChange, setFieldValue) => {
    const { value } = e.target;
    handleChange(e); // Utiliza la función handleChange de Formik

    try {
      const billsResponse = await fetchBills(value);
      const bills = billsResponse.data;
      setUserBills(bills);

      const contractResponse = await axios.get(`${BASE_URL}/contract/${value}`);
      const contractData = contractResponse.data.data;
      const { User, Delivery } = contractData;

      setFieldValue("municipio", Delivery.municipio);
      setFieldValue("direccion", Delivery.direccion);
      setFieldValue("contract", value);

      const username = User.name_razonSocial;
      const n_documento = User.n_documento;
      setNameRazonSocial(username);
      setFieldValue("username", username);
      setFieldValue("n_documento", n_documento);
    } catch (error) {
      console.error("Error al obtener los detalles del contrato:", error);
    }
  };

  const handleBillChange = (selectedBill, isChecked) => {
    const updatedBills = isChecked
      ? [...selectedBills, selectedBill]
      : selectedBills.filter((bill) => bill.numberI !== selectedBill.numberI);

    setSelectedBills(updatedBills);

    // Update the description state
    if (updatedBills.length > 0) {
      setDescription(`${updatedBills[0].description}`);
    } else {
      setDescription("Sin factura seleccionada");
    }
  };

  const totalAmount = selectedBills.reduce(
    (total, bill) => total + bill.price,
    0
  );

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      values.cashierName = username;
      values.importe = totalAmount; // Use totalAmount here
      values.description = description; // Use description here

      console.log("Fecha de pago enviada:", values.paymentDate);
      console.log("Hora de pago enviada:", values.paymentTime);

      const response = await axios.post(`${BASE_URL}/caja`, values);
      const newIngreso = response.data.data.newIngreso;

      if (newIngreso) {
        setResponse(newIngreso);
        dispatch(fetchLastReceiptNumber());
        setShowPDF(true);
        resetForm({ values: initialValues });
        dispatch(
          addReceipt({
            receipt: newIngreso.receipt,
            paymentDate: newIngreso.paymentDate,
            paymentTime: newIngreso.paymentTime,
            n_contrato: newIngreso.n_contrato,
            username: newIngreso.username,
            importe: newIngreso.importe,
            description: newIngreso.description,
            paymentMethod: newIngreso.paymentMethod,
            cashierName: newIngreso.cashierName,
            contract: newIngreso.contract,
            n_documento: newIngreso.n_documento,
          })
        );
      } else {
        console.error(
          "No se encontró 'newIngreso' en la respuesta del backend."
        );
      }
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    }
    setSubmitting(false);
  };

  const handleResetForm = (resetForm) => {
    resetForm({ values: initialValues });
    setSelectedBills([]);
    setDescription("");
  };

  const formattedDateForDisplay = format(new Date(formattedDate), "dd-MM-yyyy");

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid>
        {userRole !== 1 && (
          <Grid>
            <Grid
              style={{
                backgroundColor: "#e0e0e0",
                padding: "5px",
                marginBottom: "5px",
                marginTop: "10px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                {selectedOption
                  ? "Recibos - Movimientos - Datos clientes"
                  : "Seleccione el tipo de movimiento:"}
              </Typography>
            </Grid>
            {!selectedOption && (
              <Grid className={classes.buttonGroup}>
                <Button
                  onClick={() => setSelectedOption("Ingreso")}
                  variant="contained"
                  style={{ background: "#206482", padding: "15px" }}
                >
                  Ingresos
                </Button>

                <Button
                  onClick={() => history.push("/movements")}
                  variant="contained"
                  style={{ background: "#206482", padding: "15px" }}
                >
                  Cierre de Caja
                </Button>
                <Button
                  onClick={() => history.push("/resumen")} ////
                  variant="contained"
                  style={{ background: "#206482", padding: "15px" }}
                >
                  Ver Resumen
                </Button>

                <Button
                  onClick={() => history.push("/datosClientes")}
                  variant="contained"
                  style={{
                    background: "#206482",
                    padding: "15px",
                    color: "white",
                  }}
                >
                  Ir a Datos Clientes
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {selectedOption && userRole !== 1 && (
          <>
            <Grid className={classes.buttonGroup}>
              <Button
                variant="contained"
                style={{ background: "#206482" }}
                type="button"
                onClick={() => history.push("/resumen")}
              >
                Ver Resumen
              </Button>
              <Button
                variant="contained"
                style={{ background: "#206482" }}
                type="button"
                onClick={() => history.push("/clientes")}
              >
                Datos clientes
              </Button>
              <Button
                variant="contained"
                style={{ background: "#206482" }}
                onClick={() => history.push("/movements")}
              >
                Cierre de Caja
              </Button>
              <Button
                variant="contained"
                style={{ background: "#206482" }}
                onClick={() => history.push("/perfil")}
              >
                Volver
              </Button>
            </Grid>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
              }) => (
                <Form>
                  {selectedOption === "Ingreso" && (
                    <>
                      <FormGroup className={classes.formGroup}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              className={classes.textField}
                              label="Número de Recibo"
                              variant="outlined"
                              type="text"
                              id="receipt"
                              name="receipt"
                              value={values.receipt}
                            />
                            <ErrorMessage
                              name="receipt"
                              component="div"
                              className="error-message"
                            />

                            <TextField
                              className={classes.textField}
                              label="Fecha de Pago"
                              variant="outlined"
                              type="text"
                              id="paymentDate"
                              name="paymentDate"
                              placeholder="Fecha de Pago"
                              value={formattedDateForDisplay}
                              readOnly
                            />
                            <ErrorMessage
                              name="paymentDate"
                              component="div"
                              className="error-message"
                            />

                            <TextField
                              className={classes.textField}
                              label="Hora de Pago"
                              variant="outlined"
                              type="text"
                              id="paymentTime"
                              name="paymentTime"
                              placeholder="Hora de Pago"
                              value={formattedTime}
                              readOnly
                            />
                            <ErrorMessage
                              name="paymentTime"
                              component="div"
                              className="error-message"
                            />

                            <TextField
                              className={classes.textField}
                              label="Numero de contrato"
                              variant="outlined"
                              type="text"
                              id="n_contrato"
                              name="n_contrato"
                              placeholder="Numero de contrato"
                              required
                              onChange={(e) =>
                                handleContractChange(
                                  e,
                                  handleChange,
                                  setFieldValue
                                )
                              }
                            />
                            <ErrorMessage
                              name="n_contrato"
                              component="div"
                              className="error-message"
                            />

                            {Array.isArray(userBills)}
                            <Select
                              className={classes.textField}
                              label="Facturas"
                              variant="outlined"
                              placeholder="Facturas"
                              id="bills"
                              name="bills"
                              onChange={(e) => {
                                const selectedBillNumberI = e.target.value;
                                const selectedBill = userBills.find(
                                  (bill) => bill.numberI === selectedBillNumberI
                                );
                                if (selectedBill) {
                                  handleBillChange(selectedBill, setFieldValue); // Pass setFieldValue as an argument
                                  setFieldValue("bills", selectedBillNumberI);
                                }
                              }}
                              value={values.bills || ""}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {userBills.map((bill) => (
                                <MenuItem
                                  key={bill.numberI}
                                  value={bill.numberI}
                                >
                                  {bill.numberI}
                                </MenuItem>
                              ))}
                            </Select>

                            <ErrorMessage
                              name="contract"
                              component="div"
                              className="error-message"
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>

                      <FormGroup className={classes.formGroup}>
                        <Grid container spacing={2}>
                          <TextField
                            className={classes.textField}
                            type="text"
                            variant="outlined"
                            id="username"
                            name="username"
                            placeholder="Nombre de Usuario"
                            value={name_razonSocial || ""}
                            readOnly
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="error-message"
                          />
                          <TextField
                            className={classes.textField}
                            type="text"
                            variant="outlined"
                            id="municipio"
                            name="municipio"
                            placeholder="Municipio"
                            value={values.municipio}
                            readOnly
                          />
                          <ErrorMessage
                            name="municipio"
                            component="div"
                            className="error-message"
                          />
                          <TextField
                            className={classes.textField}
                            type="text"
                            variant="outlined"
                            id="direccion"
                            name="direccion"
                            placeholder="Direccion"
                            value={values.direccion}
                            readOnly
                          />
                          <ErrorMessage
                            name="direccion"
                            component="div"
                            className="error-message"
                          />

                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            type="text"
                            id="description"
                            name="description"
                            placeholder="Descripción"
                            value={description}
                            readOnly
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error-message"
                          />
                        </Grid>
                      </FormGroup>

                      <FormGroup className={classes.formGroup}>
                        <Grid container spacing={2}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            type="text"
                            id="cashierName"
                            name="cashierName"
                            placeholder="Tu Nombre"
                            value={username}
                            readOnly
                          />
                          <ErrorMessage
                            name="cashierName"
                            component="div"
                            className="error-message"
                          />

                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            type="text"
                            id="importe"
                            name="importe"
                            placeholder="Importe"
                            value={totalAmount}
                            readOnly
                          />
                          <ErrorMessage
                            name="importe"
                            component="div"
                            className="error-message"
                          />
                          <Select
                            className={classes.textField}
                            variant="outlined"
                            label="Metodo de pago"
                            placeholder="Metodo de pago"
                            id="paymentMethod"
                            name="paymentMethod"
                            value={values.paymentMethod || ""}
                            onChange={handleChange} // Usa handleChange de Formik
                            required
                          >
                            <MenuItem value="" disabled>
                              <em>Selecciona Método de Pago</em>
                            </MenuItem>
                            <MenuItem value="Davivienda">
                              PSE - Davivienda
                            </MenuItem>
                            <MenuItem value="corresponsalBancario">
                              Corresponsal Bancario
                            </MenuItem>
                            <MenuItem value="efectivo">Efectivo</MenuItem>
                          </Select>
                          <FormHelperText>
                            <ErrorMessage
                              name="paymentMethod"
                              component="div"
                              className="error-message"
                            />
                          </FormHelperText>
                        </Grid>
                      </FormGroup>
                    </>
                  )}
                  <Grid className={classes.buttonGroup}>
                    <Button
                      type="button"
                      variant="contained"
                      style={{ background: "#206482" }}
                      onClick={() => handleResetForm(resetForm)}
                    >
                      Limpiar Datos
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ background: "#206482" }}
                    >
                      Enviar Pago
                    </Button>

                    {showPDF && (
                      <PDFDownloadLink
                        document={
                          <Document>
                            <Page>
                              <View
                                style={{
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: 20,
                                  }}
                                >
                                  <Image
                                    src={Logo}
                                    style={{
                                      width: "25%",
                                      height: "auto",
                                      marginLeft: 20,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      backgroundColor: "#D7d7d7",
                                      marginRight: 10,
                                    }}
                                  >
                                    Número de Recibo:{" "}
                                    {response && response.receipt}
                                  </Text>
                                </View>

                                <View
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 15,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      backgroundColor: "#D7d7d7",
                                      marginTop: 2,
                                      marginBottom: 20,
                                    }}
                                  >
                                    Detalle de Pago
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Fecha de Pago:{" "}
                                    {response && response.paymentDate}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Hora: {response && response.paymentTime}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Usuario: {response && response.username}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Descripción:{description}
                                    {selectedBills.length >
                                      `Pago de factura ${selectedBills
                                        .map((bill) => `#${bill.numberI}`)
                                        .join(", ")}`}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Importe: {totalAmount}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  ></Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Método de Pago:{" "}
                                    {response && response.paymentMethod}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Cajero: {response && response.cashierName}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      marginTop: 10,
                                      alignSelf: "flex-start",
                                      textAlign: "left",
                                    }}
                                  >
                                    Contrato N°: {response && response.contract}
                                  </Text>
                                </View>
                              </View>
                            </Page>
                          </Document>
                        }
                        fileName="recibo_pago.pdf"
                      >
                        {({ blob, url, loading, error }) => (
                          <Button
                            variant="contained"
                            className={classes.buttonGroup}
                            style={{ background: "#206482" }}
                            type="button"
                          >
                            {loading ? "Generando PDF..." : "Descargar PDF"}
                          </Button>
                        )}
                      </PDFDownloadLink>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Grid>
    </Container>
  );
}

export default Encashment;
