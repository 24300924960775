import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getUserByDocument } from '../../Redux/Actions/actions';
import { Button, Grid } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
        textAlign: 'center',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    tableContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '600px', // Ancho máximo de la tabla
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        textAlign: 'left',
    },
}));

export default function ContactClient() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { n_documento } = useParams();
    const userInfo = useSelector(state => state.userInfo);

    useEffect(() => {
        dispatch(getUserByDocument(n_documento));
    }, [dispatch, n_documento]);

    if (!userInfo || !userInfo.data) {
        return <div>Cargando...</div>;
    }

    const clientData = userInfo.data;
    const contract = clientData.Contracts && clientData.Contracts[0];
    const delivery = contract && contract.Delivery;

    return (
        <Grid container className={classes.container}>
            <Grid item xs={2}>
                <Link to="/datosClientes">
                    <Button  variant="contained" style={{ background: "#206482", padding: "10px", color:"white", marginTop:"10%", marginLeft:"10px"}} >Volver</Button>
                </Link>
            </Grid>
            <Grid item xs={12}>
                
            </Grid>
            <Grid  className={classes.tableContainer}>
            <h2 style={{ marginTop: "30px", marginBottom: "30px", backgroundColor: "#e0e0e0" }}>Detalles del Cliente: {clientData.n_documento}</h2>
                <div className="user-table">
                    <table className={classes.table}>
                        <tbody>
                            <tr>
                                <th>Número de Documento:</th>
                                <td>{clientData.n_documento}</td>
                            </tr>
                            <tr>
                                <th>Nombre:</th>
                                <td>{clientData.name_razonSocial}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{clientData.email}</td>
                            </tr>
                            <tr>
                                <th>Teléfono 1:</th>
                                <td>{contract ? contract.tel1 : ''}</td>
                            </tr>
                            <tr>
                                <th>Teléfono 2:</th>
                                <td>{contract ? contract.tel2 : ''}</td>
                            </tr>
                            <tr>
                                <th>Teléfono 3:</th>
                                <td>{contract ? contract.tel3 : ''}</td>
                            </tr>
                            <tr>
                                <th>Fecha de Nacimiento:</th>
                                <td>{clientData.fecha_cumple}</td>
                            </tr>
                            <tr>
                                <th>Dirección:</th>
                                <td>{delivery ? `${delivery.direccion}, ${delivery.municipio}` : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Grid>
        </Grid>
    );
}
