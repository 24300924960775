import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, MenuList, Paper, IconButton, Menu, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: '60px', 
  },
  paper: {
    backgroundImage: 'linear-gradient(to bottom, #1f2852, #22c4cf)', 
    color: 'white', 
  },
}));

const AppMenu = () => {
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const user = useSelector((state) => state.authentication.user);
  const n_documento = user ? user.n_documento : null;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles(); 

  const menuOptions = [
    { label: 'Ver Perfil', rol: [1, 2, 3, 4], path: '/perfil' },
    { label: 'Cambiar Contraseña', rol: [1, 2, 3, 4], path: `/changePassword/${n_documento}` },
    { label: 'Mi Cuenta', rol: [1], path: '/resumen' },
    { label: 'Dashboard', rol: [4], path: 'https://www.control.fhanred.com/' },
    { label: 'Clientes', rol: [2, 3, 4], path: '/clientes' },
    { label: 'Caja', rol: [2, 3, 4], path: '/caja' },
    //{ label: 'Datos Cliente', rol: [2, 3, 4], path: '/datosClientes' }, // VER SI LO PUEDEN VER LOS CAJEROS
  ];

  const filteredOptions = menuOptions.filter(option => option.rol.includes(user?.id_role));

  const handleMenuItemClick = (path) => {
    if (path.startsWith('http')) {
      window.open(path, '_blank');
    } else {
      history.push(path);
    }
    setAnchorEl(null);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menu} 
      >
        <Paper className={classes.paper}> {/* Agrega el fondo de color al Paper */}
          <MenuList>
            {filteredOptions.map((option, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(option.path)}>
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Menu>
    </div>
  );
};

export default AppMenu;
