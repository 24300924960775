import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    divider: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: '#dce7ea',
        opacity: 0,
        animation: '$fadeIn 2s forwards',
      },
      '@keyframes fadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
      },
}));

const Dividers = ({ isVisible }) => {
  const classes = useStyles();
  return <div className={classes.divider} style={{ opacity: isVisible ? 1 : 0 }} />;
};

export default Dividers;
