import React, { useEffect, useState } from "react";
import { NavLink, Redirect, Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserContractsAction } from "../../../Redux/Actions/actions";
import { makeStyles } from "@mui/styles";
import LogoImagen from "./imageLogo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "linear-gradient(to bottom, #242d58, #79c8ce)",
  },
  profileContainerAll: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: 'Georgia, "Times New Roman", Times, serif',
    letterSpacing: "-0.2px",
    fontSize: "16px",
    color: "aliceblue",
    minHeight: "100vh",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  profileImageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "50px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
      marginBottom: "20px",
      flexDirection: "column",
    },
  },
  profileContainerImg: {
    width: "150px",
    height: "150px",
    backgroundColor: "#1f2852",
    borderRadius: "50%",
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginBottom: "20px",
  },
  profileData: {
    textAlign: "center",
  },
  profileContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  select: {
    background: "#206482",

    color: "white",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        marginBottom: theme.spacing(2),
        marginRight: "0",
      },
    },
  },
}));

function Perfil() {
  const classes = useStyles();
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const user = useSelector((state) => state.authentication.user);
  const n_documento = user ? user.n_documento : null;
  const userRole = user ? user.id_role : null;
  const hasContracts = useSelector((state) => state.userContracts);
  const dispatch = useDispatch();
  const [selectedContract, setSelectedContract] = useState("");
  const [profileImage, setProfileImage] = useState(
    user ? user.profileImage : ""
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchUserContractsAction(user.n_documento));
    }
  }, [dispatch, user]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        
      };
      reader.readAsDataURL(file);
    }
  };

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  const handleContractChange = (event) => {
    setSelectedContract(event.target.value);
    if (event.target.value) {
      window.location.href = `/contract/${event.target.value}`;
    }
  };

  const hasRole = (role) => userRole === role;

  return (
    <div className={classes.root}>
      <Container className={classes.profileContainerAll}>
        <div className={classes.profileImageContainer}>
          <div
            className={classes.profileContainerImg}
            style={{ backgroundImage: `url(${profileImage || LogoImagen})` }}
          >
            <input
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="profileImageInput"
            />
            <label
              htmlFor="profileImageInput"
              style={{ width: "100%", height: "100%" }}
            ></label>
          </div>
          <div className={classes.profileData}>
            <Typography variant="h4">{user.razon_social}</Typography>
            <Typography variant="subtitle1">{getRolName(userRole)}</Typography>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "lowercase" }}
            >
              {user.email}
            </Typography>
          </div>
        </div>

        <Grid className={classes.buttonGroup}>
          <NavLink to="/resumen" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{ background: "#206482", padding: "12px" }}
            >
              Estado de Cuenta
            </Button>
          </NavLink>
          <NavLink
            to={`/changePassword/${n_documento}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              style={{ background: "#206482", padding: "12px" }}
            >
              Cambiar Contraseña
            </Button>
          </NavLink>
          <Link to="/generarContrato" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{ background: "#206482", padding: "12px" }}
            >
              CONTRATA FHANRED
            </Button>
          </Link>
          {hasContracts && hasContracts.length > 0 && (
            <Select
              value={selectedContract}
              onChange={handleContractChange}
              variant="outlined"
              displayEmpty
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    color: "white",
                    background: "#206482",
                    padding: "12px",
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                Mis Contratos
              </MenuItem>
              {hasContracts.map((contract) => (
                <MenuItem key={contract.n_contrato} value={contract.n_contrato}>
                  Contrato #{contract.n_contrato}
                </MenuItem>
              ))}
            </Select>
          )}
          {(hasRole(2) || hasRole(3)) && (
            <NavLink
              to="/tareasAsignadas"
              style={{ textDecoration: "none", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                style={{ background: "#206482", padding: "12px" }}
              >
                Tareas
              </Button>
            </NavLink>
          )}
        </Grid>
      </Container>
    </div>
  );
}

function getRolName(rol) {
  switch (rol) {
    case 0:
      return "Usuario no cliente";
    case 1:
      return "Cliente";
    case 2:
      return "Técnico";
    case 3:
      return "Cajero";
    case 4:
      return "Administrador";
    default:
      return "Rol desconocido";
  }
}

export default Perfil;
